import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import { getBooking } from "../../store/booking/bookingAction";
import { get } from "lodash/object";

export const ChangeGymBooking = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { booking } = useSelector((state) => state.book);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();

  const [initData, setInitData] = useState({
    title: "Booking",
    fields: [
      {
        name: "class.gym.name",
        label: "Gym name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Gym name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "user.first_name",
        label: "Member name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Gym name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "class.name",
        label: "Class name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Class name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "class_schedule.coach.name",
        label: "Coach name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Coach name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "schedule_date.date",
        label: "Schedule Date",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Schedule date",
        disabled: false,
        isRequired: true,
      },
      {
        name: "class_schedule.credits",
        label: "Credits",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Credits",
        disabled: false,
        isRequired: true,
      },
      {
        name: "createdAt",
        label: "Booking Date",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Booking Date",
        disabled: false,
        isRequired: true,
      },
      {
        name: "cancelation_date",
        label: "Cancelation Date",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Cancelation Date",
        disabled: false,
        isRequired: true,
      },
      {
        name: "schedule_date.start_time",
        label: "Start Time",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Start Time",
        disabled: false,
        isRequired: true,
      },
      {
        name: "class_schedule.end_time",
        label: "End Time",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "End Time",
        disabled: false,
        isRequired: true,
      },
      {
        name: "class_schedule.duration",
        label: "Duration (In Minutes)",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Duration (In Minutes)",
        disabled: false,
        isRequired: true,
      },
      {
        name: "percentage",
        label: "Percentage",
        value: "0",
        dir: "ltr",
        type: "text",
        placeholder: "Percentage",
        disabled: false,
        isRequired: true,
      },
      {
        name: "return_credits",
        label: "Credits Returns",
        value: "0",
        dir: "ltr",
        type: "text",
        placeholder: "Credits Returns",
        disabled: false,
        isRequired: true,
      },
      {
        label: "User details",
        url: "/members/view/",
        name: "user.id",
        type: "url",
      },
    ],
  });

  useEffect(() => {
    if (id) {
      dispatch(getBooking(id));
    }
  }, []);

  useEffect(() => {
    if (booking?.id) {
      updateFields();
    }
  }, [booking]);

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = isView;
          switch (field.type) {
            case "file":
              return field;

            default:
              field.value = get(booking, field.name)
                ? get(booking, field.name)
                : field.value;
              return field;
          }
        }),
      };
    });
    if (booking?.attachment !== undefined) {
      setAttachment(booking.attachment);
    }
    if (booking?.attachments) {
      setAttachments(booking.attachments);
    }
  };

  return (
    <EntityCreateLayout
      isLoading={isLodaing}
      initData={initData}
      attachment={attachment}
      attachments={attachments}
      isView={isView}
    />
  );
};
