import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  getAllCategories,
  updateCategory,
} from "../../store/categories/categoriesAction";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";

export const ShowCategoriesTable = () => {
  const cat = useSelector((state) => state.cat);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Categories",
    routePath: "/categories",
    addNewText: "Add a new category",
    sideMenuItem: "categories",
    isBreadcrumbs: true,
    isAddNew: true,
    isSearch: true,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        maxWidth: 75,
        filterable: false,
      },
      {
        Header: "Name",
        type: "string",
        accessor: "name",
        paddingTop: "35px",
      },
      {
        Header: "Description",
        accessor: "description",
        type: "string",
        paddingTop: "35px",
      },
      {
        Header: "Action",
        type: "actions",
        maxWidth: 350,
        isViewButton: true,
        isEditButton: true,
        isStatusButton: true,
      },
    ],
  };

  const searchFields = ["name", "description"];
  let history = useHistory();

  const updateItem = (is_active, id, history) => {
    dispatch(updateCategory(is_active, id, history));
  };

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={cat.category}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
    />
  );
};
