import {
  GET_ERRORS,
  CLEAR_ERRORS,
  LOADING,
  CLEAR_LOADING,
  POP_UP_CLOSE,
  CLEAR_POP_UP_CLOSE,
  CATEGORY_NOTIFICATION,
  CLEAR_CATEGORY_NOTIFICATION,
} from "../types";

const initialState = {
  error: "",
  isLodaing: false,
  popup: false,
  catenotification: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: "",
      };
    case LOADING:
      return {
        ...state,
        isLodaing: true,
      };
    case CLEAR_LOADING:
      return {
        ...state,
        isLodaing: false,
      };
    case POP_UP_CLOSE:
      return {
        ...state,
        popup: true,
      };
    case CLEAR_POP_UP_CLOSE:
      return {
        ...state,
        popup: false,
      };
    case CATEGORY_NOTIFICATION:
      return {
        ...state,
        catenotification: true,
      };
    case CLEAR_CATEGORY_NOTIFICATION:
      return {
        ...state,
        catenotification: false,
      };
    default:
      return state;
  }
}
