import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import { getAllContacts } from "../../store/contactUs/contactusAction";

export const ShowContactUsTable = () => {
  const con = useSelector((state) => state.con);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Contact Us",
    routePath: "/contact-us",
    addNewText: "Add new contact us",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: false,
    isDateFilters: false,
    isTimeFilters: false,
    isPriceFilter: false,
    isCsvConvert: true,
    isExcelConvert: true,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        accessor: "id",
        maxWidth: 75,
        filterable: false,
      },
      {
        Header: "Member Name",
        accessor: "name",
        paddingTop: "35px",
      },
      {
        Header: "Member Message",
        accessor: "message",
        paddingTop: "35px",
      },
      {
        Header: "Email",
        accessor: "email",
        paddingTop: "35px",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        paddingTop: "35px",
      },
    ],
  };

  const searchFields = ["name", "message", "email", "mobile"];
  let history = useHistory();

  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "Message", key: "message" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
  ];

  const excelHeaders = [
    {
      label: "Name",
      fieldName: "name",
      valuePath: "name",
    },
    {
      label: "Message",
      fieldName: "message",
      valuePath: "message",
    },
    {
      label: "Email",
      fieldName: "email",
      valuePath: "email",
    },
    {
      label: "Mobile",
      fieldName: "mobile",
      valuePath: "mobile",
    },
  ];

  const customFilter = ({ start_date, end_date }) => {
    dispatch(getAllContacts(start_date, end_date));
  };

  useEffect(() => {
    dispatch(getAllContacts());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={con.contacts}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      customFilter={customFilter}
      csvHeaders={csvHeaders}
      excelHeaders={excelHeaders}
    />
  );
};
