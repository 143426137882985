import React from "react";
import Header from "../Header";
import SideMenu from "../SideMenu";
import Body from "../Body";

export const LayoutWithSidebar = ({ children, sideMenuTab }) => {
  return (
    <div className="skin-blue ">
      <div className="wrapper">
        <Header />
        <SideMenu active={sideMenuTab} />
        <Body>{children}</Body>
      </div>
    </div>
  );
};
