import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  updateSettings,
} from "../../store/settings/settingAction";
import { CustomInput } from "../common/customInput";

export const Settings = () => {
  const { settings } = useSelector((state) => state.settings);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const validateField = (name, value) => {
    if (parseInt(value) < 0 || value === "") return false;
    setErrors((prevState) => {
      delete prevState[name];
      return prevState;
    });
    return true;
  };

  const handleSubmit = (name, value) => {
    const isValid = validateField(name, value);
    const setting = settings.filter((setting) => setting.type === name)[0];
    setting.value = value;
    if (!isValid) {
      setErrors((prevState) => {
        return {
          ...prevState,
          [name]: `${setting.title} is required!`,
        };
      });
      return;
    }
    dispatch(updateSettings(setting.id, setting));
  };

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  return (
    <div>
      <section className="content-header">
        <h1>Settings</h1>
        <ol className="breadcrumb">
          <li>
            <a>
              <i className="fa fa-dashboard" aria-hidden="true" /> Dashboard
            </a>
          </li>
          <li className="active">Settings</li>
        </ol>
      </section>
      <div className={"row content"}>
        {settings.map((setting) => {
          return (
            <div key={setting.id} className={"form-group col-md-12"}>
              {errors[setting.type] ? (
                <span style={{ color: "red" }}>{errors[setting.type]}</span>
              ) : (
                ""
              )}
              <CustomInput
                value={setting.value}
                type={"number"}
                name={setting.type}
                label={setting.title}
                handleSubmit={handleSubmit}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
