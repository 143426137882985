import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import { clearCategory, getReview } from "../../store/reviews/reviewActions";
import { get } from "lodash/object";

export const ChangeGymReview = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { review } = useSelector((state) => state.rev);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();

  const [initData, setInitData] = useState({
    title: "Review",
    fields: [
      {
        name: "user.first_name",
        label: "User Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "User Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description",
        label: "User Review",
        value: "",
        dir: "ltr",
        type: "textarea",
        placeholder: "User Review",
        disabled: false,
        isRequired: true,
      },
      {
        name: "gym.name",
        label: "Gym Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Gym Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "user.mobile",
        label: "User Mobile",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "User Mobile",
        disabled: false,
        isRequired: true,
      },
    ],
  });

  const clearItem = () => {
    dispatch(clearCategory());
  };

  useEffect(() => {
    dispatch(getReview(id));
  }, []);

  useEffect(() => {
    if (review.id) {
      updateFields();
    }
  }, [review]);

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = isView;
          switch (field.type) {
            case "file":
              return field;
            default:
              field.value = get(review, field.name)
                ? get(review, field.name)
                : field.value;
              field.disabled = isView;
              return field;
          }
        }),
      };
    });
    if (review?.attachment !== undefined) {
      setAttachment(review.attachment);
    }
    if (review.attachments) {
      setAttachments(review.attachments);
    }
  };

  return (
    <EntityCreateLayout
      isLoading={isLodaing}
      initData={initData}
      attachment={attachment}
      attachments={attachments}
      isView={isView}
      clearItem={clearItem}
    />
  );
};
