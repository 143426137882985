import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import { getAllClassesReport } from "../../store/classes/classesAction";

export const ShowClassesReports = () => {
  const cla = useSelector((state) => state.cla);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Classes Report",
    routePath: "/reports/classes-admin",
    addNewText: "Add new classes report",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: true,
    isDateFilters: true,
    isTimeFilters: false,
    isPriceFilter: false,
    isCsvConvert: true,
    isExcelConvert: true,
    columns: [
      {
        Header: "S.No",
        id: "row",
        accessor: "id",
        type: "index",
        maxWidth: 75,
      },
      {
        Header: "Class Name",
        accessor: "name",
        paddingTop: "35px",
      },
      {
        Header: "Description",
        accessor: "description",
        paddingTop: "35px",
      },
      {
        Header: "Creation Date",
        accessor: "createdAt",
        paddingTop: "35px",
      },
    ],
  };

  const searchFields = ["name", "createdAt", "description", "address", "city"];
  let history = useHistory();

  const csvHeaders = [
    { label: "Class Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Class Created Date", key: "createdAt" },
  ];

  const excelHeaders = [
    {
      label: "Class Name",
      fieldName: "name",
      valuePath: "name",
    },
    {
      label: "Description",
      fieldName: "description",
      valuePath: "description",
    },
    {
      label: "Class Created Date",
      fieldName: "createdAt",
      valuePath: "createdAt",
    },
  ];

  const customFilter = ({ start_date, end_date }) => {
    dispatch(getAllClassesReport(start_date, end_date));
  };

  const clearFilter = () => {
    dispatch(getAllClassesReport());
  };

  useEffect(() => {
    dispatch(getAllClassesReport());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={cla.classes}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      customFilter={customFilter}
      csvHeaders={csvHeaders}
      excelHeaders={excelHeaders}
      clearFilter={clearFilter}
      csvFilePrefix="classes_report"
    />
  );
};
