import React, { Component } from "react";
import { connect } from "react-redux";
import { clearErrors } from "../../store/errors/errorAction";
import { otpVerification } from "../../store/auth/authActions";
import { verificationPageValidation } from "../../validation/validation";
import isEmpty from "../../validation/is-empty";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      otp: "",
      is_forgot: true,
      isShowEditUser: false,
      errors: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.error)) {
      return {
        errors: { common: props.errors.error },
      };
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { email, otp, is_forgot } = this.state;
    const userData = {
      email,
      otp: parseInt(otp),
      is_forgot,
    };

    const { errors, isValid } = verificationPageValidation(userData);
    if (isValid) {
      this.props.clearErrors();
      this.props.otpVerification(userData, this.props.history);
    } else if (errors) {
      this.setState({ errors });
    } else {
      this.setState({ email: "", otp: "" });
    }
  };

  onChange = (e) => {
    const errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
      delete errors.common;
    }
    this.props.clearErrors();
    this.setState({ [e.target.name]: e.target.value, errors });
  };

  render() {
    const { email, otp, errors } = this.state;
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <a /* href="#" */>
              <b>Verification</b>
            </a>
          </div>
          <div className="login-box-body">
            <p className="login-box-msg">
              <b className="signin">Verification</b>
            </p>
            <form action="#" method="post">
              <div className="form-group has-feedback">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                />

                <span className="glyphicon glyphicon-envelope form-control-feedback" />
                {errors.email ? (
                  <span style={{ color: "red" }}>{errors.email}</span>
                ) : null}
              </div>
              <div className="form-group has-feedback">
                <input
                  type="otp"
                  className="form-control"
                  placeholder="OTP"
                  name="otp"
                  value={otp}
                  onChange={this.onChange}
                />
                <span className="glyphicon glyphicon-lock form-control-feedback" />
                {errors.otp ? (
                  <span style={{ color: "red" }}>{errors.otp}</span>
                ) : null}
              </div>
              {errors.common ? (
                <span style={{ color: "red" }}>{errors.common}</span>
              ) : null}

              <div className="row">
                <button
                  onClick={this.onSubmit}
                  className="btn btn-primary btn-block btn-flat"
                >
                  <b>Submit</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { otpVerification, clearErrors })(
  Login
);
