import React, { Component, Fragment } from "react";
import Header from "../Header";
import SideMenu from "../SideMenu";
import Body from "../Body";
import {
  clearPopupClose,
  deleteSchedule,
  getAllCreditAmount,
  getAllSchedule,
  getAllScheduleCalendar,
  responseMessageFalse,
  updateSchedule,
} from "../../store/schedules/scheduleAction";
import { getClassSchedule } from "../../store/classes/classesAction";
import { getAllCoaches } from "../../store/coaches/coachesAction";
import { connect } from "react-redux";
import Table from "../common/Table";
import Search from "../common/Search";
import Spinner from "../common/Spinner";
import Addschedule from "./addSchedule";
import ViewSchedule from "./viewSchedule";
import EditSchedule from "./editSchedule";
import Deleteschedule from "./deleteSchedule";
import isEmpty from "../../validation/is-empty";
import { clearErrors } from "../../store/errors/errorAction";
import Calendar from "react-calendar";
import Cancelschedule from "./cancelSchedule";
import moment from "moment";
import { weekDays } from "../../utils/constants";
import Axios from "axios";
import { API_URI } from "../../utils/config";

export class schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowViewUser: false,
      isShowEditUser: false,
      isShowAddUser: false,
      selectCoach: {},
      filteredData: [],
      searchInput: "",
      rating_avg: "",
      isShowViewCoach: false,
      isShowDeleteGym: false,
      isShowAddSchedule: false,
      isShowCancelSchedule: false,
      ssss: [],
      errors: {},
      date: "",
    };
  }

  componentDidMount() {
    this.props.getClassSchedule();
    this.props.getAllSchedule();
    Axios.post(API_URI + "/role");
    this.props.getAllCoaches();
    this.props.clearErrors();
    this.props.getAllCreditAmount();
    this.props.responseMessageFalse();
  }

  componentWillUnmount() {
    this.props.clearErrors();
    this.props.responseMessageFalse();
  }
  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.error && props.sch.response === true)) {
      return {
        errors: { common: props.errors.error },
      };
      // props.responseMessageFalse()
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }

  handleCancelDeleteGym = () => {
    this.setState({ isShowDeleteGym: false, selectCoach: {} });
  };
  handleCancelSchedule = () => {
    this.setState({ isShowCancelSchedule: false, selectCoach: {} });
  };

  handleDeleteClick = (id) => {
    const { schedules } = this.props.sch;
    let selectCoach = schedules.find((gym) => gym.id === id);
    this.setState({ isShowDeleteGym: true, selectCoach });
  };

  handleDeleteGym = (id) => {
    this.props.deleteSchedule(id);
    let { filteredData } = this.state;
    filteredData = filteredData.filter((gym) => gym.id !== id);
    this.setState({ filteredData, isShowDeleteGym: false, selectCoach: {} });
  };

  handleCancelViewUser = () => {
    this.setState({ isShowViewUser: false, selectCoach: {} });
  };

  handleCancelViewCoach = () => {
    this.setState({ isShowViewCoach: false, selectCoach: {} });
  };

  handleViewClick = (id) => {
    const { schedules } = this.props.sch;
    let selectCoach = schedules.find((coach) => coach.id === id);
    this.setState({ isShowViewUser: true, selectCoach });
  };

  handleCancelEditUser = () => {
    this.setState({ isShowEditUser: false, selectCoach: {} });
    this.props.clearPopupClose();
  };
  handleCancelEditSchedule = () => {
    this.setState({ isShowAddSchedule: true, selectCoach: {} });
  };

  handleEditClick = (id) => {
    const { schedules } = this.props.sch;
    let selectCoach = schedules.find((coach) => coach.id === id);
    this.setState({ isShowEditUser: true, selectCoach });
  };

  handleCancelClick = (id) => {
    // let is_admin_cancel = selectCoach.is_cancel ? false : true;
    this.props.updateSchedule({ is_admin_cancel: true }, id);
    this.setState({ isShowCancelSchedule: false, selectCoach: {} });
  };
  handleopenClick = (id) => {
    const { schedules } = this.props.sch;
    let selectCoach = schedules.find((gym) => gym.id === id);
    this.setState({ isShowCancelSchedule: true, selectCoach });
  };

  handleSetData = (data, searchInput) => {
    this.setState({ filteredData: data, searchInput });
  };

  handleCancelAddUser = () => {
    this.setState({ isShowAddSchedule: false });
  };

  handleAddClick = () => {
    this.setState({ isShowAddSchedule: true });
  };

  handleFilter = (date) => {
    this.setState({ date: date });
    // let { date } = this.state;

    let d = new Date(date);
    date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

    this.props.getAllScheduleCalendar(date);
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      isShowViewUser,
      selectCoach,
      isShowEditUser,
      filteredData,
      searchInput,
      isShowDeleteGym,
      isShowAddSchedule,
      errors,

      isShowCancelSchedule,
    } = this.state;

    const { schedules } = this.props.sch;
    const { user } = this.props.auth;
    const isViewer = user.role_id === 5;
    /*  let sehe1 = schedules.map(sehe => {
     this.state.ssss.push(sehe.class.name)
   })
   */

    const { isLodaing } = this.props.errors;

    let headerStyle = {
      height: 45,
      paddingTop: 15,

      fontWeight: "bold",
    };
    const columns = [
      {
        Header: "Class Name",
        id: "name",
        accessor: "class.name",
        maxWidth: 130,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.class ? row.original.class.name : ""}</div>;
        },
      },

      {
        Header: "Start Time",
        id: "start_time",
        accessor: "start_time",
        maxWidth: 50,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return (
            <div>
              {moment(row.original.start_time, "HH:mm:ss").format("HH:mm")}
            </div>
          );
        },
      },
      {
        Header: "Duration",
        id: "duration",
        accessor: "duration",
        maxWidth: 50,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.duration}</div>;
        },
      },
      {
        Header: "Spots",
        id: "spots",
        accessor: "spot",
        maxWidth: 80,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.spot}</div>;
        },
      },
      {
        Header: "Days",
        id: "days",
        maxWidth: 100,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          const isDaily = row.original.routine === "Daily";
          return (
            <div>
              {isDaily
                ? ""
                : weekDays
                    .filter((day, index) =>
                      row.original.routine_days?.includes(index)
                    )
                    .join(",")}
            </div>
          );
        },
      },
      {
        Header: "Frequency",
        id: "frequency",
        accessor: "routine",
        maxWidth: 55,
        headerStyle: headerStyle,
        filterable: false,
        Cell: (row) => {
          return <div>{row.original.routine}</div>;
        },
      },
      {
        Header: "Action",
        headerStyle: headerStyle,
        getProps: () => {
          return {
            style: {
              textAlign: "center",
            },
          };
        },
        Cell: (props) => (
          <Fragment>
            <button
              type="button"
              className="btn btn-info"
              data-toggle="modal"
              data-target="#modal-default"
              title="View"
              style={{ marginLeft: "10px" }}
              onClick={() => this.handleViewClick(props.original.id)}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
            {isViewer ? (
              <span />
            ) : (
              <span>
                {!props.original.is_admin_cancel ? (
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    title="Edit"
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.handleEditClick(props.original.id)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    title="Edit"
                    style={{ marginLeft: "10px" }}
                    disabled
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-danger"
                  data-toggle="tooltip"
                  title="Delete"
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.handleDeleteClick(props.original.id)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
                {!props.original.is_admin_cancel ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-toggle="tooltip"
                    title="Cancel"
                    style={{ marginLeft: "10px" }}
                    onClick={() => this.handleopenClick(props.original.id)}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-toggle="tooltip"
                    title="Cancelled"
                    style={{ marginLeft: "10px" }}
                    disabled
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                )}
              </span>
            )}
          </Fragment>
        ), // Custom cell components!
      },
    ];

    const fields = ["start_date", "start_time", "credits", "duration"];
    const relationFields = {
      class: ["name"],
      coach: ["name"],
    };

    return (
      <div className="skin-blue ">
        <div className="wrapper">
          <Header />
          <SideMenu active="schedule" />
          <Body>
            <section className="content-header">
              <h1>Schedule</h1>
              <ol className="breadcrumb">
                <li>
                  <a /* href="#" */>
                    <i className="fa fa-dashboard"></i> Dashboard
                  </a>
                </li>
                <li className="active">Schedule</li>
              </ol>
            </section>

            {!isLodaing ? (
              <section className="content">
                <div className="row">
                  <div className="col-xs-12">
                    {errors.common ? (
                      <span
                        style={{ color: "red" }}
                        onClick={this.scrollToTop()}
                      >
                        {errors.common}
                      </span>
                    ) : null}
                    {isViewer ? null : (
                      <div
                        className="pull-right"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="classaddbutton">Schedule a class </div>
                        <button
                          type="button"
                          className="btn btn-success"
                          data-toggle="tooltip"
                          title="Add"
                          style={{ marginRight: "10px" }}
                          onClick={this.handleAddClick}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>{" "}
                        <Search
                          data={schedules}
                          handleSetData={this.handleSetData}
                          fields={fields}
                          relationFields={relationFields}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-xs-8">
                    <div className="box">
                      <div className="box-body">
                        <Table
                          data={
                            filteredData.length > 0
                              ? filteredData
                              : searchInput
                              ? filteredData
                              : schedules
                          }
                          columns={columns}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="pul">
                      {
                        <Calendar
                          onChange={this.handleFilter}
                          value={this.state.date}
                        />
                      }
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <Spinner />
            )}
            {isShowViewUser ? (
              <ViewSchedule
                handleCancelViewUser={this.handleCancelViewUser}
                user={selectCoach}
              />
            ) : null}
            {isShowEditUser ? (
              <EditSchedule
                handleCancelEditUser={this.handleCancelEditUser}
                editSchedule={selectCoach}
                handleCancelViewCoach={this.handleCancelViewCoach}
              />
            ) : null}
            {isShowDeleteGym ? (
              <Deleteschedule
                handleCancelDeleteGym={this.handleCancelDeleteGym}
                handleDeleteClick={this.handleDeleteGym}
                user={selectCoach}
              />
            ) : null}
            {isShowAddSchedule ? (
              <Addschedule
                handleCancelAddUser={this.handleCancelAddUser}
                handleCancelEditSchedule={this.handleCancelEditSchedule}
              />
            ) : null}
            {isShowCancelSchedule ? (
              <Cancelschedule
                handleCancelDeleteGym={this.handleCancelSchedule}
                handleopenClick={this.handleCancelClick}
                user={selectCoach}
              />
            ) : null}
          </Body>
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sch: state.sch,
  auth: state.auth,
  cla: state.cla,
  coach: state.coach,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getAllSchedule,
  getAllScheduleCalendar,
  getClassSchedule,
  getAllCreditAmount,
  deleteSchedule,
  clearPopupClose,
  clearErrors,
  getAllCoaches,
  updateSchedule,
  responseMessageFalse,
})(schedule);
