import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  children,
  requiredRoles = [],
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PrivateElement
          Component={Component}
          requiredRoles={requiredRoles}
          {...props}
        >
          {children}
        </PrivateElement>
      )}
    />
  );
};

const PrivateElement = ({ Component, children, requiredRoles, ...props }) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userHasRequiredRole = requiredRoles.includes(user.roleId);
  return isAuthenticated && userHasRequiredRole ? (
    <>{Component ? <Component {...props} /> : children}</>
  ) : (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
