// Production Server
//export const API_URI = "http://52.87.52.218:4002/api/v1";
//export const IMAGE_URI = "http://52.87.52.218:4002";

// website url api.classtap.com  Server
//export const API_URI = "https://api.classtap.com/api/v1";
//export const IMAGE_URI = "https://api.classtap.com";

// Demo Server for testing
// export const API_URI = "http://3.224.108.26:4002/api/v1";
// export const IMAGE_URI = "http://3.224.108.26:4002";

// Current settings by process
export const API_URI = process.env.REACT_APP_API_URI;
export const IMAGE_URI = process.env.REACT_APP_IMAGE_URI;
export const GOOGLE_MAP_API_KEY = "AIzaSyB_Lz_b22Sf5eKRSHhgxOnoZ8InrtXkpSM";

// Development Server
//export const API_URI = "http://3.224.108.26:4001/api/v1";
//export const IMAGE_URI = "http://3.224.108.26:4001";
