import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  deleteClass,
  getAllClasses,
  updateClass,
} from "../../store/classes/classesAction";

export const ShowClassesTable = () => {
  const cla = useSelector((state) => state.cla);
  const { isLodaing } = useSelector((state) => state.errors);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const isViewer = user.role_id === 5;

  const tableProps = {
    name: "Classes",
    routePath: "/classes",
    addNewText: "Add a new class",
    sideMenuItem: "classes",
    isBreadcrumbs: true,
    isAddNew: isViewer ? false : true,
    isSearch: true,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        maxWidth: 75,
      },
      {
        Header: "Image",
        maxWidth: 150,
        type: "image",
        accessor: "image",
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 150,
      },
      {
        Header: "Description",
        accessor: "description",
        maxWidth: 200,
      },
      {
        Header: "Rating",
        type: "rating",
        id: "row_star",
        accessor: "rating_avg",
        maxWidth: 150,
        filterable: false,
      },
      isViewer
        ? {
            Header: "Action",
            type: "actions",
            isViewButton: true,
            isEditButton: false,
            isStatusButton: false,
            isDeleteButton: false,
          }
        : {
            Header: "Action",
            type: "actions",
            isViewButton: true,
            isEditButton: true,
            isStatusButton: true,
            isDeleteButton: true,
            isInHome: true,
          },
    ],
  };

  const searchFields = ["name", "description"];
  let history = useHistory();

  const updateItem = (is_active, id, history) => {
    dispatch(updateClass(is_active, id, history));
  };

  const deleteItem = (id) => {
    dispatch(deleteClass(id));
  };

  useEffect(() => {
    dispatch(getAllClasses());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={cla.classes}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
      deleteItem={deleteItem}
    />
  );
};
