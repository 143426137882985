import {
  GYM_BRANCHES,
  GET_GYM_BRANCHES,
  GET_SINGLE_GYM,
  GET_SUB_ADMINS,
  GET_GYM_STATS,
  GET_GYM,
  GET_CLEAR_CLASS,
} from "../types";

const initialState = {
  gym: {},
  gyms: [],
  attachments: [],
  getgymstats: [],
  selectGym: {},
  viewgym: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GYM_BRANCHES:
      return {
        ...state,
        gym: action.payload,
      };

    case GET_GYM_BRANCHES:
      return {
        ...state,
        gyms: action.payload,
      };
    case GET_SINGLE_GYM:
      return {
        ...state,
        viewgym: action.payload,
        // gym: {}
      };
    case GET_GYM:
      return {
        ...state,
        selectGym: action.payload,
      };
    case GET_SUB_ADMINS:
      return {
        ...state,
        gyms: action.payload,
      };
    case GET_GYM_STATS:
      return {
        ...state,
        getgymstats: action.payload,
      };
    case GET_CLEAR_CLASS:
      return {
        ...state,
        selectGym: {},
        viewgym: {},
      };

    default:
      return state;
  }
}
