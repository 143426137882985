import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  GET_VIEWER,
  GET_ALL_VIEWERS,
  LOADING,
  CLEAR_LOADING,
} from "../types";
import { toast } from "react-toastify";

// Get all Viewer
export const getAllViewer = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/viewers`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_VIEWERS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Add Viewer for Form Registration
export const addViewer = (viewerData, history) => (dispatch, getState) => {
  dispatch(setLoading());
  let viewer = [...getState().viewer.viewers];
  axios
    .post(`${API_URI}/viewers`, viewerData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        viewer.push(data);

        toast("New Viewer Added!", {
          position: "top-center",
        });
        dispatch({
          type: GET_ALL_VIEWERS,
          payload: viewer,
        });
        history.push("/viewers");
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Get single Viewer Details in view
export const getViewer = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/viewers/${id}`)
    .then(async (res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_VIEWER,
          payload: data,
        });

        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Update Viewer
export const updateViewer =
  (viewerData, id, history) => (dispatch, getState) => {
    dispatch(setLoading());
    let viewers = [...getState().viewer.viewers];
    axios
      .put(`${API_URI}/viewers/${id}`, viewerData)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;

          dispatch({
            type: GET_VIEWER,
            payload: data,
          });

          viewers = viewers.map((viewer) => {
            if (viewer.id === data.id) {
              viewer = { ...viewer, ...data };
            }

            return viewer;
          });

          toast("Viewer Updated!", {
            position: "top-center",
          });

          dispatch({
            type: GET_ALL_VIEWERS,
            payload: viewers,
          });
          if (history) {
            history.push("/viewers");
          }
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
      });
  };

// Delete viewer
export const deleteViewer = (id) => (dispatch, getState) => {
  let viewers = [...getState().viewer.viewers];

  axios
    .delete(`${API_URI}/viewers/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        viewers = viewers.filter((viewer) => viewer.id !== id);
        dispatch({
          type: GET_ALL_VIEWERS,
          payload: viewers,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};
