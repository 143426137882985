import React from "react";

export const HomeButton = ({ isInHome, id, onButtonClick }) => {
  return isInHome ? (
    <button
      type="button"
      className="btn btn-warning"
      data-toggle="tooltip"
      title="Status"
      style={{ marginLeft: "10px", background: "green", borderColor: "green" }}
      onClick={() => onButtonClick(id)}
    >
      <i className="fa fa-home" aria-hidden="true" />
    </button>
  ) : (
    <button
      type="button"
      className="btn btn-status"
      data-toggle="tooltip"
      title="Status"
      style={{ marginLeft: "10px" }}
      onClick={() => onButtonClick(id)}
    >
      <i className="fa fa-home" aria-hidden="true" />
    </button>
  );
};
