import React from "react";
import { Link } from "react-router-dom";

export const EditButton = ({ urlPath, id, onButtonClick }) => {
  return (
    <Link to={`${urlPath}/edit/${id}`}>
      <button
        type="button"
        className="btn btn-success"
        data-toggle="tooltip"
        title="Edit"
        style={{ marginLeft: "10px" }}
        onClick={(id) => onButtonClick(id)}
      >
        <i className="fa fa-pencil" aria-hidden="true" />
      </button>
    </Link>
  );
};
