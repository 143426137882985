import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  deleteViewer,
  getAllViewer,
  updateViewer,
} from "../../store/viewers/viewerActions";

export const ShowViewersTable = () => {
  const viewer = useSelector((state) => state.viewer);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const tableProps = {
    name: "Manage Viewer",
    routePath: "/viewers",
    addNewText: "Add a new viewer",
    sideMenuItem: "gyms",
    isBreadcrumbs: true,
    isAddNew: true,
    isSearch: true,
    columns: [
      {
        Header: "Id",
        accessor: "id",
        maxWidth: 50,
      },
      {
        Header: "Image",
        maxWidth: 150,
        type: "image",
        accessor: "image",
        filterable: false,
      },
      {
        Header: "First Name",
        accessor: "first_name",
        maxWidth: 150,
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        maxWidth: 150,
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        maxWidth: 150,
      },
      {
        Header: "Email",
        accessor: "email",
        maxWidth: 150,
      },
      {
        Header: "Gym",
        accessor: "gym.name",
        maxWidth: 150,
      },
      {
        Header: "Action",
        type: "actions",
        isViewButton: true,
        isEditButton: true,
        isStatusButton: true,
        isDeleteButton: true,
      },
    ],
  };

  const searchFields = ["name", "description"];
  let history = useHistory();

  const updateItem = (is_active, id) => {
    dispatch(updateViewer(is_active, id));
  };

  const deleteItem = (id) => {
    dispatch(deleteViewer(id));
  };

  useEffect(() => {
    dispatch(getAllViewer());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={viewer.viewers}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
      deleteItem={deleteItem}
    />
  );
};
