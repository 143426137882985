import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearErrors } from "../../store/errors/errorAction";

export const ErrorsHandler = ({ ...props }) => {
  const { error } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error.message) {
      toast.error(error.message, {
        position: "top-center",
      });
      dispatch(clearErrors());
    }
  }, [error]);

  return <Fragment {...props} />;
};
