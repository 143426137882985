import { GET_ALL_VIEWERS, GET_VIEWER } from "../types";

const initialState = {
  viewer: {},
  viewers: [],
  attachments: [],
  selectViewer: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_VIEWERS:
      return {
        ...state,
        viewers: action.payload,
      };
    case GET_VIEWER:
      return {
        ...state,
        viewer: action.payload,
      };

    default:
      return state;
  }
}
