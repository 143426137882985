import {
  GET_ALL_REVIEWS,
  GET_REVIEWS,
  GET_CLEAR_CATEGORY,
  GET_REVIEWS_COACH,
  GET_ALL_COACH_REVIEWS,
  GET_REVIEW_STATS,
} from "../types";

const initialState = {
  reviews: [],
  review: {},
  cate: {},
  reviewstats: [],
  coachreviews: [],
  coachreview: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case GET_REVIEWS:
      return {
        ...state,
        review: action.payload,
      };
    case GET_CLEAR_CATEGORY:
      return {
        ...state,
        review: {},
      };
    case GET_REVIEW_STATS:
      return {
        ...state,
        reviewstats: action.payload,
      };
    case GET_ALL_COACH_REVIEWS:
      return {
        ...state,
        coachreviews: action.payload,
      };
    case GET_REVIEWS_COACH:
      return {
        ...state,
        coachreview: action.payload,
      };

    default:
      return state;
  }
}
