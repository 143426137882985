import React from "react";

export const IsActiveButton = ({ isActive, id, onButtonClick }) => {
  return isActive ? (
    <button
      type="button"
      className="btn btn-warning"
      data-toggle="tooltip"
      title="Status"
      style={{ marginLeft: "10px" }}
      onClick={(id) => onButtonClick(id)}
    >
      <i className="fa fa-check" aria-hidden="true" />
    </button>
  ) : (
    <button
      type="button"
      className="btn btn-status"
      data-toggle="tooltip"
      title="Status"
      style={{ marginLeft: "10px" }}
      onClick={() => onButtonClick(id)}
    >
      <i className="fa fa-close" aria-hidden="true" />
    </button>
  );
};
