import { useSelector } from "react-redux";
import React from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";

export const ShowUserSubscriptions = () => {
  const user1 = useSelector((state) => state.auth.user1);

  const { isLodaing } = useSelector((state) => state.errors);

  const tableProps = {
    name: "Subscriptions",
    routePath: "/user/subscriptions",
    addNewText: "Add a new subscription",
    isBreadcrumbs: false,
    isAddNew: false,
    isSearch: false,
    columns: [
      {
        Header: "S.No",
        id: "row",
        maxWidth: 75,
        filterable: false,
        type: "index",
      },
      {
        Header: "Id",
        id: "subscription.id",
        accessor: "subscription.id",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Name",
        id: "subscription.name",
        accessor: "subscription.name",
        filterable: false,
      },
      {
        Header: "Subscribed",
        id: "createdAt",
        accessor: "createdAt",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Credits",
        id: "subscription.credits",
        filterable: false,
        accessor: "subscription.credits",
      },
      {
        Header: "Validity In Months",
        id: "subscription.period",
        accessor: "subscription.period",
        filterable: false,
      },
      {
        Header: "Price",
        id: "subscription.price",
        filterable: false,
        accessor: "subscription.price",
      },
      {
        Header: "Transaction",
        id: "transaction_id",
        filterable: false,
        accessor: "transaction_id",
      },
    ],
  };

  const searchFields = ["name", "id"];
  let history = useHistory();

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={user1.user_subscriptions}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
    />
  );
};
