import React from "react";

export const EditPopupButton = ({ id, onButtonClick }) => {
  return (
    <button
      type="button"
      className="btn btn-success"
      data-toggle="tooltip"
      title="Edit"
      style={{ marginLeft: "10px" }}
      onClick={() => onButtonClick(id)}
    >
      <i className="fa fa-pencil" aria-hidden="true" />
    </button>
  );
};
