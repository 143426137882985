import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  GET_ALL_CLASSES,
  GET_SINGLE_CLASS,
  GET_CLASS,
  LOADING,
  CLEAR_LOADING,
  GET_GYM_CLASS,
  GET_CLEAR_CLASS,
  GET_ALL_GENDER,
  ALL_CLASSES_SESSIONS,
} from "../types";
import { toast } from "react-toastify";
import moment from "moment";

export const getAllClasses = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    let gym_id = localStorage.getItem("gym_id");
    const filter = JSON.stringify({ where: { gym_id: gym_id } });
    const res = await getClasses(filter);
    if (res.data.error.code) {
      dispatch({
        type: GET_ERRORS,
        payload: res.data.error,
      });
    } else {
      const { data } = res.data;
      dispatch({
        type: GET_ALL_CLASSES,
        payload: data,
      });
      dispatch(clearLoading());
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
    dispatch(clearLoading());
  }
};

const getClasses = async (filter) => {
  return axios.get(`${API_URI}/getAllClasses?filter=${filter}`);
};

export const getAllActiveClasses = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    let gym_id = localStorage.getItem("gym_id");
    const filter = JSON.stringify({
      where: { gym_id: gym_id, is_active: true },
    });
    const res = await getClasses(filter);
    if (res.data.error.code) {
      dispatch({
        type: GET_ERRORS,
        payload: res.data.error,
      });
    } else {
      const { data } = res.data;
      dispatch({
        type: GET_ALL_CLASSES,
        payload: data,
      });
      dispatch(clearLoading());
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.error,
    });
    dispatch(clearLoading());
  }
};

// Add Classes
export const addClasses = (classData, history) => (dispatch, getState) => {
  dispatch(setLoading());
  let classes = [...getState().cla.classes];
  axios
    .post(`${API_URI}/classes`, classData)

    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        dispatch({
          type: GET_ALL_CLASSES,
          payload: classes,
        });
        const { data } = res.data;
        classes.push(data);

        dispatch({
          type: GET_ALL_CLASSES,
          payload: classes,
        });
      }
      dispatch(clearLoading());
      toast("New Class Added!", {
        position: "top-center",
      });
      if (classData.gym_id) {
        history.push("/classes");
      } else {
        history.push("/admin-classes");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(clearLoading());
    });
};

// Get single Class Details in view
export const getClass = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/getSingleClasses/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_CLASS,
          payload: data,
        });
        dispatch({
          type: GET_SINGLE_CLASS,
          payload: data,
        });

        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Update Class
export const updateClass = (classData, id, history) => (dispatch, getState) => {
  dispatch(setLoading());
  axios
    .put(`${API_URI}/classes/${id}`, classData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        let classes = getState().cla.classes;
        dispatch(filterUpdatedData(classes, data));

        toast("Class Updated!", {
          position: "top-center",
        });
        dispatch(clearLoading());
        if (!history) {
          return;
        }
        if (classData.gym_id || getState().auth?.user?.gym?.id) {
          history.push("/classes");
        } else {
          history.push("/admin-classes");
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Delete Class
export const deleteClass = (id) => (dispatch, getState) => {
  let classes = [...getState().cla.classes];

  axios
    .delete(`${API_URI}/classes/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        classes = classes.filter((user) => user.id !== id);
        dispatch({
          type: GET_ALL_CLASSES,
          payload: classes,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

// Delete image in class page
export const deleteClassImage = (id, gymId) => (dispatch, getState) => {
  let classes = [...getState().cla.classes];
  let selectClass = { ...getState().cla.selectClass };
  axios
    .delete(`${API_URI}/attachments/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        toast("Image Deleted!", {
          position: "top-center",
        });
        classes = classes.map((gym) => {
          if (gym.id === gymId) {
            gym.attachments = gym.attachments.filter(
              (attachment) => attachment.id !== id
            );
          }

          return gym;
        });
        const newSelectClass = selectClass;
        newSelectClass.attachments = selectClass.attachments.filter(
          (attachment) => {
            return attachment.id !== gymId;
          }
        );

        /*dispatch({
          type: GET_ALL_CLASSES,
          payload: classes,
        });*/

        dispatch({
          type: GET_SINGLE_CLASS,
          payload: newSelectClass,
        });

        selectClass.attachments = selectClass.attachments.filter(
          (attachment) => attachment.id !== id
        );

        dispatch({
          type: GET_CLASS,
          payload: selectClass,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// class count
export const getStatsClass = () => async (dispatch) => {
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ where: { gym_id: gym_id } });
  axios
    .get(`${API_URI}/classes/stats?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_GYM_CLASS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getAllClassesForReport = (filters) => async (dispatch) => {
  const startTimeFilter = {
    start_time: {
      $gte: filters?.start_time,
    },
  };

  const endTimeFilter = {
    end_time: {
      $lte: filters?.end_time,
    },
  };

  const classCategoryFilter = {
    inClassCategory: {
      category_id: { $in: [filters?.category] },
    },
  };

  const classGenderFilter = {
    inClass: {
      gender_id: filters?.gender,
    },
  };

  const filter = JSON.stringify({
    inScheduleDates: {
      date: {
        $gte: moment(filters?.start_date).format("YYYY-MM-DD"),
        $lte: moment(filters?.end_date).format("YYYY-MM-DD"),
      },
      ...(filters?.start_time ? startTimeFilter : {}),
      ...(filters?.end_time ? endTimeFilter : {}),
    },
    ...(filters?.category ? classCategoryFilter : {}),
    ...(filters?.gender ? classGenderFilter : {}),
  });

  axios
    .get(`${API_URI}/classes/?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        let allClasses = data.map((currentClass) => {
          const classSchedules = currentClass.class_schedules;
          delete currentClass.class_schedules;
          const classData = currentClass;

          classSchedules.map((schedule) => {
            return schedule.schedule_dates.map((item) => {
              item.class = classData;
              item.duration = schedule.duration;
              item.price = schedule.price;
              item.credits = schedule.credits;
              item.day = moment(item.date).format("dddd");
              item.coach = schedule.coach;
            });
          });

          return {
            ...currentClass,
            class_schedules: classSchedules,
          };
        });

        allClasses = allClasses
          .map((aClass) => aClass.class_schedules)
          .flat()
          .map((item) => item.schedule_dates)
          .flat();
        if (filters?.day) {
          allClasses = allClasses.filter((cl) => cl.day === filters?.day);
        }
        dispatch({
          type: ALL_CLASSES_SESSIONS,
          payload: allClasses,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const clearClass = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CLASS,
  });
};

export const getAllGenders = () => async (dispatch) => {
  axios
    .get(`${API_URI}/genders`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_GENDER,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const adminGetAllClasses = () => async (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/getAllClasses`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch(clearLoading());
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_CLASSES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Update Class admin
export const adminUpdateClass = (classData, id, history) => (dispatch) => {
  dispatch(setLoading());

  axios
    .put(`${API_URI}/classes/${id}`, classData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch(filterUpdatedData(data));
        history.push("/adminclasses");
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

const filterUpdatedData = (classes, data) => (dispatch) => {
  const newClasses = classes.map((classItem) => {
    if (classItem.id === data.id) {
      classItem = { ...classItem, ...data };
    }
    return classItem;
  });

  dispatch({
    type: GET_CLASS,
    payload: data,
  });
  dispatch({
    type: GET_ALL_CLASSES,
    payload: newClasses,
  });
};

export const getAllClassesReport =
  (start_date = "", end_date = "") =>
  async (dispatch) => {
    dispatch(setLoading());
    let url = `${API_URI}/getAllClasses`;

    if (start_date && end_date) {
      const filter = JSON.stringify({
        where: {
          createdAt: {
            $between: [`${start_date} 00:00:01`, `${end_date} 23:59:59`],
          },
        },
      });
      url = `${url}?filter=${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch(clearLoading());
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_CLASSES,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
        dispatch(clearLoading());
      });
  };

export const getAllGymClassesReport =
  (start_date = "", end_date = "") =>
  async (dispatch) => {
    dispatch(setLoading());
    let gym_id = localStorage.getItem("gym_id");
    let url = `${API_URI}/getAllClasses`;
    let filter = "";

    if (start_date && end_date) {
      filter = JSON.stringify({
        where: {
          createdAt: {
            $between: [`${start_date} 00:00:01`, `${end_date} 23:59:59`],
          },
          gym_id: gym_id,
        },
      });
      url = `${url}?filter=${filter}`;
    } else {
      filter = JSON.stringify({ where: { gym_id: gym_id } });
      url = `${url}?filter=${filter}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_CLASSES,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
        dispatch(clearLoading());
      });
  };

export const getClassSchedule = () => async (dispatch) => {
  dispatch(setLoading());
  let d = new Date();
  let start_date =
    d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({
    where: {
      start_date: { $gte: `${start_date}` },
      gym_id: gym_id,
      is_active: true,
    },
  });
  axios
    .get(`${API_URI}/getAllClasses?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_CLASSES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

// Update Class in view
export const updateClassView = (classData, id) => (dispatch, getState) => {
  dispatch(setLoading());
  let classes = [...getState().cla.classes];

  axios
    .put(`${API_URI}/classes/${id}`, classData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        classes = classes.map((cla) => {
          if (cla.id === data.id) {
            cla = { ...cla, ...data };
          }
          return cla;
        });
        dispatch({
          type: GET_CLASS,
          payload: data,
        });
        dispatch({
          type: GET_ALL_CLASSES,
          payload: classes,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};
