import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";

import isEmpty from "../../validation/is-empty";
import {
  getAllUser,
  getAllUserFilter,
  updateUser,
} from "../../store/auth/authActions";
import moment from "moment";

export const ShowUserTable = () => {
  const auth = useSelector((state) => state.auth);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Members",
    routePath: "/members",
    addNewText: "Add new user",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: true,
    isDateFilters: false,
    isTimeFilters: false,
    isNewsletterFilters: true,
    isPriceFilter: false,
    isCsvConvert: true,
    columns: [
      {
        Header: "Member Id",
        accessor: "id",
        maxWidth: 120,
      },
      {
        Header: "First Name",
        accessor: "first_name",
        maxWidth: 100,
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        maxWidth: 100,
      },
      {
        Header: "Promo Code",
        accessor: "corporate_code",
        maxWidth: 100,
      },
      {
        Header: "Date Joined",
        accessor: "createdAt",
        maxWidth: 100,
        Cell: function getSubscriptionValidity(row) {
          return (
            <div>
              {row.original.createdAt
                ? moment(row.original.createdAt).format("DD/MM/YYYY")
                : "-"}
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        maxWidth: 150,
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        maxWidth: 150,
      },
      {
        Header: "Credits Remaining",
        accessor: "credits_balance",
        maxWidth: 150,
      },
      {
        Header: "Last Purchase",
        accessor: "user_subscriptions",
        maxWidth: 150,
        Cell: function getLastSubscription(row) {
          const userSubscriptions = row.original.user_subscriptions || [];
          const lastSubscription = userSubscriptions[0];
          return (
            <div>
              {lastSubscription
                ? moment(lastSubscription.createdAt).format("DD/MM/YYYY")
                : "-"}
            </div>
          );
        },
      },
      {
        Header: "Subscription Validity",
        accessor: "subscription_validity",
        Cell: function getSubscriptionValidity(row) {
          const subscriptionEndDate = moment(
            row.original.subscription_validity
          ).format("DD/MM/YYYY");
          return (
            <div>
              {moment(subscriptionEndDate, "DD/MM/YYYY").isValid()
                ? subscriptionEndDate
                : "-"}
            </div>
          );
        },
      },
      {
        Header: "News Letter",
        paddingTop: "35px",
        accessor: "is_newsletter",
        Cell: function newsLetters(row) {
          return (
            <div>
              {!isEmpty(row.original.is_newsletter) &&
              row.original.is_newsletter.toString()
                ? "Enabled"
                : "Disabled"}
            </div>
          );
        },
      },
      {
        Header: "Action",
        type: "actions",
        width: 200,
        isViewButton: true,
        isEditButton: true,
        isStatusButton: true,
      },
    ],
  };

  const searchFields = [
    "id",
    "first_name",
    "is_newsletter",
    "last_name",
    "email",
    "mobile",
  ];
  let history = useHistory();

  const csvHeaders = [
    { label: "Member Id", key: "id" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Promo Code", key: "corporate_code" },
    { label: "Date Joined", key: "createdAt" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Credits Remaining", key: "credits_balance" },
    { label: "Last Purchase", key: "user_subscriptions[0].createdAt" },
    { label: "Subscription Validity", key: "subscription_validity" },
    { label: "News Letter", key: "is_newsletter" },
    { label: "Verified", key: "is_verified" },
    { label: "Active", key: "is_active" },
    { label: "Device Token", key: "device_token" },
    { label: "Device Type", key: "device_type" },
  ];

  const updateItem = (is_active, id) => {
    dispatch(updateUser(is_active, id));
  };

  const customFilter = ({ isNewsLetter }) => {
    dispatch(getAllUserFilter(!!parseInt(isNewsLetter)));
  };

  const clearFilter = () => {
    dispatch(getAllUser());
  };

  useEffect(() => {
    dispatch(getAllUser());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={auth.users}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
      csvHeaders={csvHeaders}
      customFilter={customFilter}
      clearFilter={clearFilter}
      csvFilePrefix="members_list"
    />
  );
};
