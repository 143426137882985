import React from "react";

export const SaveButton = ({ handleSaveClick, id }) => {
  return (
    <button
      type="button"
      className="btn btn-info"
      data-toggle="tooltip"
      title="Save"
      style={{ marginLeft: "10px" }}
      onClick={() => handleSaveClick(id)}
    >
      <i className="fa fa-save" aria-hidden="true" />
    </button>
  );
};
