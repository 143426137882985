import { GET_SETTINGS, UPDATE_SETTINGS } from "../types";

const initialState = {
  settings: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: state.settings.map((setting) => {
          if (setting.id == action.payload.id) {
            setting.value = action.payload.value;
          }
          return setting;
        }),
      };

    default:
      return state;
  }
}
