import {
  GET_ALL_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  GET_CLEAR_CATEGORY,
  GET_SUBSCRIPTION_STATS,
} from "../types";

const initialState = {
  subscriptions: [],
  subscription: {},
  cate: {},
  selectSubscription: {},
  subscriptionstats: [],
  subb: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: action.payload,
      };

    case GET_SUBSCRIPTION:
      return {
        ...state,
        selectSubscription: action.payload,
      };
    case GET_CLEAR_CATEGORY:
      return {
        ...state,
        subscription: {},
        selectSubscription: {},
      };
    case GET_SUBSCRIPTION_STATS:
      return {
        ...state,
        subscriptionstats: action.payload,
      };

    default:
      return state;
  }
}
