import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  getUserPayments,
  getUserPaymentsFilter,
} from "../../store/auth/authActions";
import moment from "moment";

export const ShowUserPayments = () => {
  const userPayments = useSelector((state) => state.auth.userPayments);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const lastDayNextMonth = new Date(y, m + 1, 0);
  const firstDayPrevMonth = new Date(y, m - 1, 1);

  const tableProps = {
    name: "Payments",
    routePath: "/user/payments",
    addNewText: "Add a new payment",
    isBreadcrumbs: false,
    isAddNew: false,
    isSearch: true,
    isFilters: true,
    isDateFilters: true,
    isPaidFilters: true,
    isPaymentTypeFilters: true,
    isCsvConvert: true,
    isExcelConvert: true,
    defaultDateRange: {
      start_date: firstDayPrevMonth,
      end_date: lastDayNextMonth,
    },
    columns: [
      {
        Header: "Payment Id",
        maxWidth: 75,
        filterable: false,
        id: "id",
        accessor: "id",
      },
      {
        Header: "User ID",
        id: "user_id",
        accessor: "user_id",
        filterable: false,
      },
      {
        Header: "First Name",
        id: "first_name",
        accessor: "user.first_name",
        filterable: false,
        Cell: function getUserName(row) {
          return <div>{row.original?.user?.first_name}</div>;
        },
      },
      {
        Header: "Last Name",
        id: "user_last_name",
        accessor: "user.last_name",
        filterable: false,
        Cell: function getUserName(row) {
          return <div>{row.original?.user?.last_name}</div>;
        },
      },
      {
        Header: "Email",
        id: "email",
        accessor: "email",
        filterable: false,
        Cell: function getUserName(row) {
          return <div>{row.original?.user?.email}</div>;
        },
      },
      {
        Header: "Mobile",
        id: "mobile",
        accessor: "mobile",
        filterable: false,
        Cell: function getUserName(row) {
          return <div>{row.original?.user?.mobile}</div>;
        },
      },
      {
        Header: "Action type",
        id: "action_type",
        accessor: "action_type",
        filterable: false,
      },
      {
        Header: "Amount",
        id: "amount",
        accessor: "amount_in_rial",
        filterable: false,
      },
      {
        Header: "Merchant Id",
        id: "merchant_id",
        accessor: "merchant_id",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Fort Id",
        id: "transaction_id",
        filterable: false,
        accessor: "transaction_id",
      },
      {
        Header: "Paid",
        id: "is_payment",
        accessor: "is_payment",
        filterable: false,
        Cell: function getPaymentStatus(row) {
          return <div>{row.original.is_payment ? "Yes" : "No"}</div>;
        },
      },
      {
        Header: "Date",
        id: "updatedAt",
        filterable: false,
        accessor: "updatedAt",
        Cell: function getDateInfo(row) {
          return (
            <div>{moment(row.original.updatedAt).format("YYYY-MM-DD")}</div>
          );
        },
      },
      {
        Header: "Time",
        id: "updatedAt",
        filterable: false,
        accessor: "updatedAt",
        Cell: function getTimeInfo(row) {
          return <div>{moment(row.original.updatedAt).format("HH:mm:ss")}</div>;
        },
      },
    ],
  };

  const searchFields = ["first_name", "user_id"];
  let history = useHistory();

  const csvHeaders = [
    {
      label: "User ID",
      key: "user_id",
    },
    {
      label: "Merchant Id",
      key: "merchant_id",
    },
    {
      label: "Member First Name",
      key: "user.first_name",
    },
    {
      label: "Member Email",
      key: "user.email",
    },
    {
      label: "Member Last Name",
      key: "user.last_name",
    },
    {
      label: "Mobile",
      key: "user.mobile",
    },
    {
      label: "Date",
      key: "updatedAt",
    },
    {
      label: "Time",
      key: "updatedAt",
    },
    {
      label: "Amount",
      key: "amount_in_rial",
    },
    {
      label: "Action Type",
      key: "action_type",
    },
    {
      label: "Fort Id",
      key: "transaction_id",
    },
    {
      label: "Paid",
      key: "is_payment",
    },
  ];

  const excelHeaders = [
    {
      label: "User ID",
      fieldName: "user_id",
      valuePath: "user_id",
    },
    {
      label: "Merchant Id",
      fieldName: "merchantId",
      valuePath: "merchant_id",
    },
    {
      label: "Member First Name",
      fieldName: "first_name",
      valuePath: "user.first_name",
    },
    {
      label: "Member Last Name",
      fieldName: "last_name",
      valuePath: "user.last_name",
    },
    { label: "Member Email", fieldName: "email", valuePath: "user.email" },
    { label: "Mobile", fieldName: "mobile", valuePath: "user.mobile" },
    {
      label: "Date",
      fieldName: "date",
      valuePath: "updatedAt",
      fieldType: "date",
    },
    {
      label: "Time",
      fieldName: "time",
      valuePath: "updatedAt",
      fieldType: "time",
    },
    {
      label: "Amount",
      fieldName: "amount",
      fieldType: "amount",
      valuePath: "amount",
    },
    {
      label: "Action Type",
      fieldName: "action_type",
      valuePath: "action_type",
    },
    {
      label: "Fort Id",
      fieldName: "fortId",
      valuePath: "transaction_id",
    },
    {
      label: "Paid",
      fieldName: "paymentStatus",
      valuePath: "is_payment",
    },
  ];

  const customFilter = ({
    start_date,
    end_date,
    startTime: start_time,
    endTime: end_time,
    is_payment,
    action_type,
  }) => {
    dispatch(
      getUserPaymentsFilter({
        start_date,
        end_date,
        start_time,
        end_time,
        action_type,
        is_payment,
      })
    );
  };

  const clearFilter = () => {
    dispatch(getUserPayments());
  };

  useEffect(() => {
    dispatch(
      getUserPaymentsFilter({
        start_date: moment(firstDayPrevMonth).format("YYYY-MM-DD"),
        end_date: moment(lastDayNextMonth).format("YYYY-MM-DD"),
      })
    );
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={userPayments}
      searchFields={searchFields}
      tableProps={tableProps}
      csvHeaders={csvHeaders}
      excelHeaders={excelHeaders}
      customFilter={customFilter}
      history={history}
      clearFilter={clearFilter}
      csvFilePrefix="financial_report"
    />
  );
};
