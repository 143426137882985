import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../store/categories/categoriesAction";
import { clearErrors } from "../../store/errors/errorAction";
import { useHistory, useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import {
  addGym,
  clearClass,
  deleteImage,
  getGym,
  updateGym,
} from "../../store/gyms/gymActions";
import { getAllGenders } from "../../store/classes/classesAction";

export const ChangeGym = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { viewgym } = useSelector((state) => state.gym);
  const { category } = useSelector((state) => state.cat);
  const { genders } = useSelector((state) => state.cla);
  const [attachment, setAttachment] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const customFields = isView
    ? [
        {
          name: "createdAt",
          label: "Date Joined",
          value: "",
          type: "paragraph",
        },
      ]
    : [];

  const [initData, setInitData] = useState({
    title: "Gym",
    sideMenuItem: "gym",
    fields: [
      {
        name: "name",
        label: "Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "name_ar",
        label: "Name Arabic",
        dir: "rtl",
        value: "",
        type: "text",
        placeholder: "Name Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description",
        label: "Description",
        dir: "ltr",
        value: "",
        type: "textarea",
        placeholder: "Description",
        disabled: false,
        isRequired: true,
      },
      {
        name: "description_ar",
        label: "Description Arabic",
        dir: "rtl",
        value: "",
        type: "textarea",
        placeholder: "Description Arabic",
        disabled: false,
        isRequired: true,
      },
      {
        name: "gym_mobile",
        label: "Mobile",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Mobile",
        disabled: false,
        isRequired: true,
      },
      {
        name: "email",
        label: "Email",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Email",
        disabled: false,
        isRequired: true,
      },
      {
        name: "gym_categories",
        label: "Category",
        type: "dropdown",
        selectedValues: [],
        allValues: [],
        disabled: false,
        isRequired: true,
      },
      {
        name: "gender_id",
        label: "Gender",
        type: "select",
        allValues: [],
        selectedValue: 0,
        disabled: false,
        isRequired: true,
      },
      ...customFields,
      {
        name: "city",
        label: "City",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "City",
        disabled: false,
        readOnly: false,
      },
      {
        name: "address",
        label: "Address",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Address",
        disabled: false,
        readOnly: false,
      },
      {
        name: "lattitude",
        label: "Lattitude",
        shortName: "lat",
        dir: "ltr",
        value: 25.2854,
        type: "text",
        placeholder: "Lattitude",
        disabled: true,
        readOnly: true,
      },
      {
        name: "longitute",
        label: "Longitute",
        shortName: "lng",
        dir: "ltr",
        value: 51.531,
        type: "text",
        placeholder: "Longitute",
        disabled: true,
        readOnly: true,
      },

      {
        name: "google_map",
        label: "Map",
        type: "map",
      },
      {
        name: "photos",
        label: "",
        files: [],
        type: "file",
        disabled: false,
        isRequired: true,
        isSingle: false,
      },
    ],
  });

  const handleSave = (saveData) => {
    dispatch(clearErrors);
    saveData.category = saveData.gym_categories;
    if (!saveData.photos.length) {
      delete saveData.photos;
    }
    if (id) {
      dispatch(updateGym(saveData, id, history));
    } else {
      dispatch(addGym(saveData, history));
    }
  };

  const clearItem = () => {
    dispatch(clearClass());
  };

  useEffect(() => {
    if (id) {
      dispatch(getGym(id));
    }
    dispatch(getAllCategories());
    dispatch(getAllGenders());
  }, []);

  useEffect(() => {
    if (viewgym.id) {
      updateFields();
    }
  }, [viewgym]);

  useEffect(() => {
    if (category.length && genders.length) {
      updateCustomFields();
    }
  }, [category, genders]);

  const updateCustomFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = field.disabled ? field.disabled : isView;
          switch (field.type) {
            case "dropdown":
              field.allValues = category;
              return field;
            case "select":
              field.allValues = genders;
              return field;
            default:
              return field;
          }
        }),
      };
    });
  };

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = field.disabled ? field.disabled : isView;
          switch (field.type) {
            case "file":
              return field;
            case "dropdown":
              field.selectedValues = viewgym[field.name].map((categories) => {
                return categories.category;
              });
              return field;
            case "select":
              field.selectedValue = viewgym["gender_id"];
              return field;
            case "number":
              field.value = parseFloat(viewgym[field.name])
                ? parseFloat(viewgym[field.name])
                : field.value;
              return field;
            default:
              field.value = viewgym[field.name]
                ? viewgym[field.name]
                : field.value;
              return field;
          }
        }),
      };
    });
    if (viewgym?.attachment !== undefined) {
      setAttachment(viewgym.attachment);
    }
    if (viewgym?.attachments) {
      setAttachments(viewgym.attachments);
    }
  };

  const deleteFile = (attachId) => {
    dispatch(deleteImage(attachId, id));
  };

  return (
    <>
      {(viewgym.id && id) || (!viewgym.id && !id) ? (
        <EntityCreateLayout
          isLoading={isLodaing}
          initData={initData}
          submitSaveAction={handleSave}
          attachment={attachment}
          attachments={attachments}
          deleteFile={deleteFile}
          isView={isView}
          clearItem={clearItem}
        />
      ) : (
        ""
      )}
    </>
  );
};
