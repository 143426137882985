import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import { getAllCoachesReport } from "../../store/coaches/coachesAction";

export const ShowGymCoachesReports = () => {
  const coach = useSelector((state) => state.coach);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Coaches Report",
    routePath: "/reports/coaches",
    addNewText: "Add new coaches report",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: true,
    isDateFilters: true,
    isTimeFilters: false,
    isPriceFilter: false,
    isCsvConvert: true,
    isExcelConvert: true,
    columns: [
      {
        Header: "ID",
        id: "row",
        accessor: "id",
        maxWidth: 75,
      },
      {
        Header: "Coach Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Rating",
        accessor: "rating_avg",
      },
      {
        Header: "Coach Created Date",
        accessor: "createdAt",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
      },
    ],
  };

  const searchFields = [
    "name",
    "email",
    "description",
    "createdAt",
    "mobile",
    "rating_avg",
  ];
  let history = useHistory();

  const csvHeaders = [
    { label: "Coach Id", key: "id" },
    { label: "Coach Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Email", key: "email" },
    { label: "Rating", key: "rating_avg" },
    { label: "Coach Created Date", key: "createdAt" },
    { label: "Mobile", key: "mobile" },
  ];

  const excelHeaders = [
    {
      label: "Coach Name",
      fieldName: "name",
      valuePath: "name",
    },
    {
      label: "Description",
      fieldName: "description",
      valuePath: "description",
    },
    {
      label: "Email",
      fieldName: "email",
      valuePath: "email",
    },
    {
      label: "Rating",
      fieldName: "rating_avg",
      valuePath: "rating_avg",
    },
    {
      label: "Coach created date",
      fieldName: "createdAt",
      valuePath: "createdAt",
    },
    {
      label: "Mobile",
      fieldName: "mobile",
      valuePath: "mobile",
    },
  ];

  const customFilter = ({ start_date, end_date }) => {
    dispatch(getAllCoachesReport(start_date, end_date));
  };

  const clearFilter = () => {
    dispatch(getAllCoachesReport());
  };

  useEffect(() => {
    dispatch(getAllCoachesReport());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={coach.coaches}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      customFilter={customFilter}
      csvHeaders={csvHeaders}
      excelHeaders={excelHeaders}
      clearFilter={clearFilter}
      csvFilePrefix="gym_coaches_report"
    />
  );
};
