import React from "react";
import { Link } from "react-router-dom";

const BoxItem = (props) => {
  return (
    <div className="col-lg-3 col-xs-6">
      <div className={`small-box ${props.color ? props.color : "bg-yellow"}`}>
        <div className="inner">
          <h3>{props.count ? props.count : 0}</h3>
          <p>{props.title ? props.title : "title"}</p>
        </div>
        <div className="icon">
          <i
            className={`ion ${props.icon ? props.icon : "ion-person-add"}`}
          ></i>
        </div>
        <Link to={props.path ? props.path : ""} className="small-box-footer">
          More info <i className="fa fa-arrow-circle-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default BoxItem;
