import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import errorReducer from "./errors/errorReducer";
import propertyReducer from "./properties/propertyReducer";
import gymReducer from "./gyms/gymReducer";
import viewerReducer from "./viewers/viewerReducer";

import classesReducer from "./classes/classesReducer";
import coachesReducer from "./coaches/coachesReducer";
import categoryReducer from "./categories/categoryReducer";
import subscriptionReducer from "./subscriptions/subscriptionReducer";
import scheduleReducer from "./schedules/scheduleReducer";
import bookingReducer from "./booking/bookingReducer";
import reviewReducer from "./reviews/reviewReducer";
import settingReducer from "./settings/settingReducer";
import contactusReducer from "./contactUs/contactusReducer";
import attachmentReducer from "./attachments/attachmentReducer";

export default combineReducers({
  auth: authReducer,
  property: propertyReducer,
  errors: errorReducer,
  gym: gymReducer,
  viewer: viewerReducer,
  cla: classesReducer,
  coach: coachesReducer,
  cat: categoryReducer,
  sub: subscriptionReducer,
  sch: scheduleReducer,
  book: bookingReducer,
  rev: reviewReducer,
  settings: settingReducer,
  con: contactusReducer,
  pht: attachmentReducer,
});
