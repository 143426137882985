import React from "react";
import { Link } from "react-router-dom";

export const ViewButton = ({ urlPath, id, onButtonClick }) => {
  return (
    <Link to={`${urlPath}/view/${id}`}>
      <button
        type="button"
        className="btn btn-info"
        data-toggle="modal"
        data-target="#modal-default"
        title="View"
        style={{ marginLeft: "10px" }}
        onClick={(id) => onButtonClick(id)}
      >
        <i className="fa fa-eye" aria-hidden="true" />
      </button>
    </Link>
  );
};
