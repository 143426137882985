import React, { Fragment, useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import Spinner from "../common/Spinner";
import { IMAGE_URI } from "../../utils/config";
import Map from "../common/gymMap/map";
import { CustomInput } from "./entityInputs/customInput";
import { CustomDropdown } from "./entityInputs/customDropdown";
import { CustomSelect } from "./entityInputs/customSelect";
import { CustomTextarea } from "./entityInputs/customTextarea";
import { fieldValidate } from "../../validation/fieldValidate";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";

export const EntityCreateLayout = ({
  isLoading,
  initData,
  submitSaveAction = () => {},
  attachments,
  attachment,
  deleteFile = () => {},
  isView,
  clearItem = () => {},
}) => {
  const [fieldsData, setFieldsData] = useState(initData.fields);
  const [errors, setErrors] = useState({});
  const [totalImagesSize, setTotalImagesSize] = useState(0);
  const maxImagesSize = 5042880;
  let ua = navigator.userAgent.match(
      /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
    ),
    browser;
  if (
    navigator.userAgent.match(/Edge/i) ||
    navigator.userAgent.match(/Trident.*rv[ :]*11\./i)
  ) {
    browser = "msie";
  } else {
    browser = ua[1].toLowerCase();
  }

  const toBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDeleteFile = (e, fileId) => {
    e.preventDefault();
    deleteFile(fileId);
  };

  const onDropdownChange = (selectedList, selectedItem, fieldName) => {
    setFieldsData(
      fieldsData.map((field) => {
        if (field.name === fieldName) {
          field.selectedValues = selectedList;
        }
        return field;
      })
    );
  };

  const onCheckboxChange = (e) => {
    setFieldsData(
      fieldsData.map((field) => {
        if (field.name === e.target.name) {
          field.value = e.target.checked;
        }
        return field;
      })
    );
  };

  const onDateChange = (value, fieldName) => {
    setFieldsData(
      fieldsData.map((field) => {
        if (field.name === fieldName) {
          field.value = value;
        }
        return field;
      })
    );
  };

  const onSelectChange = (e) => {
    setFieldsData(
      fieldsData.map((field) => {
        if (field.name === e.target.name) {
          field.selectedValue = e.target.value;
        }
        return field;
      })
    );
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const isArabicField = /ar$/g.test(name);
    const arabicRegexp = isArabicField
      ? /^([\u0621-\u064A0-9\W ])*$/g
      : /^[\s\w\d\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]*$/g;
    const isValid = arabicRegexp.test(e.target.value);
    if (isValid) {
      setFieldsData(
        fieldsData.map((field) => {
          if (field.name === e.target.name) {
            field.value = e.target.value;
          }
          return field;
        })
      );
    }
  };

  const onDrop = async (fieldName, picture, isSingle = false) => {
    const field = fieldsData.filter(
      (fieldData) => fieldData.name === fieldName
    );
    let photos = field[0].files;
    const file = picture[picture.length - 1];

    if (picture.length !== 0) {
      const image = await toBase64(file);
      let imagdd = {
        image_data: image,
        is_primary: photos.length === 0,
        size: image.length,
      };
      photos = isSingle ? [imagdd] : [...photos, imagdd];

      setTotalImagesSize(totalImagesSize + imagdd.size);
      if (totalImagesSize + imagdd.size >= maxImagesSize) {
        setErrors({ photos: `Images size is too big` });
      } else {
        setErrors({});
      }

      delete errors.photos;
      setFieldsData(
        fieldsData.map((fieldData) => {
          if (fieldData.name === fieldName) {
            fieldData.files = photos;
          }
          return fieldData;
        })
      );
    }
  };

  const mapDataclass = (data) => {
    const mapKeys = Object.keys(data);
    setFieldsData(
      fieldsData.map((field) => {
        let index;
        if (field.shortName) {
          index = mapKeys.indexOf(field.shortName);
        } else {
          index = mapKeys.indexOf(field.name);
        }

        if (index > -1) {
          const newValue = data[mapKeys[index]];
          field.value = newValue ? newValue : "";
          if (field.name === "address" && !field.value.includes(" ")) {
            field.value = "";
          }
        }
        return field;
      })
    );
  };

  const handleDeleteTmpImage = (fileFieldName, id, size) => {
    setTotalImagesSize(totalImagesSize - size);
    if (totalImagesSize - size >= maxImagesSize) {
      setErrors({ photos: `Images size is too big` });
    } else {
      setErrors({});
    }
    const field = fieldsData.filter(
      (fieldData) => fieldData.name === fileFieldName
    );
    let files = field[0].files;
    files = files.filter((file, index) => index !== id);
    setFieldsData(
      fieldsData.map((fieldData) => {
        if (fieldData.name === fileFieldName) {
          fieldData.files = files;
        }
        return fieldData;
      })
    );
    toast("Image Deleted!", {
      position: "top-center",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { errors, isValid } = fieldValidate(
      fieldsData,
      attachment,
      attachments
    );
    if (!isValid) {
      setErrors(errors);
      return;
    }
    // array fields to object
    const saveData = {};
    fieldsData.forEach((fieldData) => {
      switch (fieldData.type) {
        case "file": {
          saveData[fieldData.name] = fieldData.files;
          break;
        }
        case "dropdown": {
          saveData[fieldData.name] = fieldData.selectedValues;
          break;
        }
        case "select": {
          saveData[fieldData.name] = fieldData.selectedValue;
          break;
        }
        case "number": {
          saveData[fieldData.name] = parseFloat(fieldData.value);
          if (isNaN(saveData[fieldData.name])) saveData[fieldData.name] = "";
          break;
        }
        case "paragraph": {
          break;
        }

        default:
          saveData[fieldData.name] = fieldData.value;
          break;
      }
    });
    submitSaveAction(saveData);
  };

  useEffect(() => {
    return () => {
      clearItem();
    };
  }, []);
  return (
    <>
      <section className="content-header">
        <h1>{initData.title}</h1>
        <ol className="breadcrumb">
          <li>
            <a>
              <i className="fa fa-dashboard" /> Dashboard
            </a>
          </li>
          <li className="active">{initData.title}</li>
        </ol>
      </section>
      {!isLoading ? (
        <section className="content">
          <div className="row">
            <div>
              {fieldsData.map((field, index) => {
                switch (field.type) {
                  case "file":
                    if (field.disabled) {
                      return;
                    }
                    return (
                      <Fragment key={index}>
                        <div className="col-md-offset-1 col-md-8 col-md-offset-1">
                          <h6 className="dimensionalign">
                            Dimensions: 250 * 250 pixels
                          </h6>
                          <h6 className="dimensionalignmb">
                            Max File Size 5mb
                          </h6>
                        </div>
                        <div className={"col-md-offset-1 col-md-8 "}>
                          {attachments.length
                            ? attachments.map((image) => (
                                <div className="col-md-10" key={image.id}>
                                  <button className="bttnicon-editgym">
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        marginLeft: "43px",
                                      }}
                                      aria-hidden="true"
                                      onClick={(e) =>
                                        handleDeleteFile(e, image.id)
                                      }
                                    />
                                  </button>
                                  <img
                                    className="row"
                                    style={{
                                      width: "250px",
                                      height: "250px",
                                      // marginLeft: "43px",
                                      marginTop: "26px",
                                    }}
                                    // className="user-image"
                                    src={`${IMAGE_URI}/${image.dir}/${image.file_name}`}
                                    alt={image.file_name}
                                  />
                                </div>
                              ))
                            : ""}
                          {attachment ? (
                            <div className="row">
                              <div className="col-md-10">
                                <label>Add Images</label>
                                <div className="row">
                                  <img
                                    style={{
                                      width: "250px",
                                      height: "250px",
                                      marginTop: "12px",
                                      marginLeft: "11px",
                                    }}
                                    src={`${IMAGE_URI}/${attachment.dir}/${attachment.file_name}`}
                                    alt="image"
                                  />
                                  <button
                                    className="bttnicon"
                                    style={{
                                      marginLeft: "-273px",
                                      marginTop: "7px",
                                    }}
                                    onClick={(e) =>
                                      handleDeleteFile(e, attachment.id)
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {field.files.map((photo, index) => (
                            <div key={index} className="row">
                              <div className="col-md-10">
                                <div className="row">
                                  <img
                                    style={{
                                      width: "250px",
                                      height: "250px",
                                      marginTop: "12px",
                                      marginLeft: "11px",
                                    }}
                                    src={photo.image_data}
                                    alt={photo.filename}
                                  />
                                  <button
                                    className="bttnicon"
                                    style={{
                                      marginLeft: "-273px",
                                      marginTop: "7px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={() =>
                                        handleDeleteTmpImage(
                                          field.name,
                                          index,
                                          photo.size
                                        )
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                          <ImageUploader
                            withIcon={false}
                            withLabel={false}
                            fileContainerStyle={{
                              width: "200px",
                              height: browser === "safari" ? "95px" : "50px",
                            }}
                            onChange={(file) => {
                              onDrop(field.name, file, field.isSingle);
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".gif",
                              ".svg",
                              "jpeg",
                            ]}
                            singleImage={true}
                          />
                          {errors.photos ? (
                            <span style={{ color: "red" }}>
                              {errors.photos}
                            </span>
                          ) : null}
                        </div>
                      </Fragment>
                    );
                  case "textarea":
                    return (
                      <CustomTextarea
                        field={field}
                        errors={errors}
                        handleChange={handleChange}
                        key={index}
                      />
                    );
                  case "date":
                    return (
                      <div className="form-group col-md-4 col-md-offset-1">
                        <label htmlFor={field.name}>{field.label}</label>
                        <DatePicker
                          type="text"
                          className="form-control"
                          selected={
                            field.value !== ""
                              ? moment(field.value).isValid()
                                ? new Date(field.value)
                                : null
                              : null
                          }
                          showTimeSelect
                          dateFormat="Pp"
                          onChange={(date) => {
                            onDateChange(
                              moment(date).format("YYYY-MM-DD HH:MM:SS"),
                              field.name
                            );
                          }}
                          date
                        />
                      </div>
                    );
                  case "paragraph":
                    return (
                      <div
                        className=" form-group col-md-4 col-md-offset-1 d-flex"
                        style={{
                          display: "flex",
                          height: "57px",
                          flexDirection: "column",
                        }}
                      >
                        <label htmlFor={field.name}>{field.label}</label>
                        <p style={{ margin: "auto 0" }}>{field.value}</p>
                      </div>
                    );
                  case "url":
                    return (
                      <div
                        className=" form-group col-md-4 col-md-offset-1 d-flex"
                        style={{
                          display: "flex",
                          alignItems: "end",
                          height: "57px",
                        }}
                      >
                        <Link to={`${field.url}${field.value}`}>
                          {field.label}
                        </Link>
                      </div>
                    );
                  case "dropdown": {
                    return (
                      <CustomDropdown
                        field={field}
                        onDropdownChange={onDropdownChange}
                        errors={errors}
                        key={index}
                      />
                    );
                  }
                  case "select": {
                    return (
                      <CustomSelect
                        field={field}
                        onSelectChange={onSelectChange}
                        errors={errors}
                        key={index}
                      />
                    );
                  }
                  case "map": {
                    return (
                      <Fragment key={index}>
                        {!isView ? (
                          <div
                            className={"col-md-8"}
                            style={{ height: "250px" }}
                          >
                            <Map
                              center={{
                                lat: fieldsData.filter((field) => {
                                  return field.name === "lattitude";
                                })[0].value,
                                lng: fieldsData.filter(
                                  (field) => field.name === "longitute"
                                )[0].value,
                              }}
                              height="250px"
                              width="750px"
                              zoom={15}
                              mapclass={mapDataclass}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    );
                  }
                  case "checkbox": {
                    return (
                      <Fragment key={index}>
                        <div
                          className={"col-md-8 col-md-offset-1 d-flex"}
                          style={{ justifyContent: "center" }}
                        >
                          <input
                            type="checkbox"
                            disabled={field.disabled}
                            className="gyminputdateschedule-checkbox"
                            style={{ margin: 12 }}
                            // value={field.value}\
                            checked={field.value}
                            name={field.name}
                            onChange={onCheckboxChange}
                          />
                          <label htmlFor="exampleInputEmail1">
                            {field.label}
                          </label>
                        </div>
                      </Fragment>
                    );
                  }
                  default:
                    return (
                      <CustomInput
                        key={index}
                        field={field}
                        handleChange={handleChange}
                        errors={errors}
                      />
                    );
                }
              })}
            </div>
          </div>
          {!isView ? (
            <button onClick={handleSubmit} disabled={errors.photos}>
              Submit
            </button>
          ) : (
            ""
          )}
        </section>
      ) : (
        <Spinner />
      )}
    </>
  );
};
