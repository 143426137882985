import React from "react";
import { connect } from "react-redux";
import BoxItem from "./BoxItem";

const Box = (props) => {
  const { getclassstats } = props.cla;
  const { getcoachstats } = props.coach;
  const { schedulestats } = props.sch;
  const { bookingStats } = props.book;
  const { reviewstats } = props.rev;
  const { user } = props.auth;
  const isViewer = user.role_id === 5;
  return (
    <div className="row">
      {isViewer ? null : (
        <BoxItem
          color={"bg-aqua"}
          path={"classes"}
          title="Classes"
          count={getclassstats}
          icon={"ion-person"}
        />
      )}
      {isViewer ? null : (
        <BoxItem
          color={"bg-aqua"}
          path={"coaches"}
          title="Coaches"
          count={getcoachstats}
          icon={"ion-person"}
        />
      )}
      <BoxItem
        color={"bg-aqua"}
        path={"Schedule"}
        title="Schedule"
        count={schedulestats}
        icon={"ion-person"}
      />
      <BoxItem
        color={"bg-aqua"}
        path={"all-bookings"}
        title="Bookings"
        count={bookingStats}
        icon={"ion-person"}
      />
      {isViewer ? null : (
        <BoxItem
          color={"bg-aqua"}
          path={"reviews/gyms"}
          title="Reviews"
          count={reviewstats}
          icon={"ion-person"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cla: state.cla,
  coach: state.coach,
  sch: state.sch,
  book: state.book,
  rev: state.rev,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Box);
