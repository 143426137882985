import axios from "axios";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  GET_ALL_CATEGORIES,
  LOADING,
  CLEAR_LOADING,
  GET_CATEGORY,
  GET_CLEAR_CATEGORY,
  GET_CATEGORY_SINGLE,
  GET_CATEGORY_STATS,
  CATEGORY_NOTIFICATION,
  CLEAR_CATEGORY_NOTIFICATION,
  CLEAR_SELECTED_CATEGORY,
} from "../types";
import { toast } from "react-toastify";

export const getAllCategories = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/categories`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_CATEGORIES,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Add categories
export const addCategory = (categoryData, history) => (dispatch, getState) => {
  dispatch(setLoading());
  let category = [...getState().cat.category];
  axios
    .post(`${API_URI}/categories`, categoryData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        category.push(data);
        dispatch({
          type: GET_ALL_CATEGORIES,
          payload: category,
        });
        dispatch({
          type: CATEGORY_NOTIFICATION,
        });
        toast("New Category Added!", {
          position: "top-center",
        });
      }
      history.push("/categories");
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      });
      dispatch(clearLoading());
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// Get single category Details in view
export const getCategory = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/categories/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_CATEGORY,
          payload: data,
        });
        dispatch({
          type: GET_CATEGORY_SINGLE,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

// Update category
export const updateCategory =
  (categoryData, id, history) => (dispatch, getState) => {
    let category = [...getState().cat.category];

    axios
      .put(`${API_URI}/categories/${id}`, categoryData)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;

          category = category.map((cat) => {
            if (cat.id === data.id) {
              cat = { ...cat, ...data };
            }

            return cat;
          });
          dispatch({
            type: GET_CATEGORY,
            payload: data,
          });
          dispatch({
            type: GET_ALL_CATEGORIES,
            payload: category,
          });
          dispatch({
            type: CATEGORY_NOTIFICATION,
          });
          toast("Category Updated!", {
            position: "top-center",
          });
          history.push("/categories");
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error,
        });
      });
  };

export const clearCategory = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CATEGORY,
  });
};

export const clearSelectedCategory = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_CATEGORY });
};

// Delete Category
export const deleteCategory = (id) => (dispatch, getState) => {
  let category = [...getState().cat.category];

  axios
    .delete(`${API_URI}/categories/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        category = category.filter((user) => user.id !== id);
        dispatch({
          type: GET_ALL_CATEGORIES,
          payload: category,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

// category count
export const getStatsCategory = () => async (dispatch) => {
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({ where: { gym_id: gym_id } });
  axios
    .get(`${API_URI}/categories/stats?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_CATEGORY_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Delete image in category page
export const deleteClassImage = (id) => (dispatch, getState) => {
  let selectCategory = { ...getState().cat.selectCategory };

  axios
    .delete(`${API_URI}/attachments/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        selectCategory.attachment = null;
        toast("Image Deleted!", {
          position: "top-center",
        });
        dispatch({
          type: GET_CATEGORY_SINGLE,
          payload: selectCategory,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

export const clearCategoryNotification = () => (dispatch) => {
  dispatch({
    type: CLEAR_CATEGORY_NOTIFICATION,
  });
};
