import axios from "axios";
import moment from "moment";

import { API_URI } from "../../utils/config";
import { clearEmpties } from "../../utils/helpers";

import {
  GET_ERRORS,
  GET_ALL_BOOKING,
  GET_BOOKING,
  LOADING,
  CLEAR_LOADING,
  GET_BOOKING_STATS,
  GET_CLEAR_CATEGORY,
  GET_ALL_UPCOMING_BOOKING,
  GET_ALL_BOOKINGS,
  GET_ALL_BOOKING_GYM_REPORT,
  GET_BOOKING_CANCELLATION_REPORT,
} from "../types";

export const getBookings = () => async (dispatch) => {
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({
    where: {
      gym_id,
    },
  });
  axios
    .get(`${API_URI}/booking_classes?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_BOOKING,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};
export const getAllBookingAdmin = () => async (dispatch) => {
  dispatch(setLoading());

  axios
    .get(`${API_URI}/booking_classes`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_BOOKING,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Get single category Details in view
export const getBooking = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/booking_classes/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_BOOKING,
          payload: data,
        });

        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// Upcoming Booking
export const getAllBookingGymLogin = () => async (dispatch) => {
  let date = new Date().toISOString().split("T")[0];
  const time = moment().seconds(0).format("HH:mm:ss");
  let url = `${API_URI}/booking_classes?filter={"where": {"is_cancel": "false", "is_admin_cancel": "false"}, "is_after": "${date}${time}" }`;
  axios
    .get(url)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_UPCOMING_BOOKING,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Upcoming Booking
export const getAllBookingAdminLogin = () => async (dispatch) => {
  axios
    .get(`${API_URI}/booking_classes`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_BOOKINGS,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Booking count
export const getStatsBooking = () => async (dispatch) => {
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({
    where: {
      gym_id,
    },
  });
  axios
    .get(`${API_URI}/booking_classes/stats?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;

        dispatch({
          type: GET_BOOKING_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const clearCategory = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CATEGORY,
  });
};

// Booking class Report
export const getAllBookingReport =
  (start_date = "", end_date = "", start_time = "", end_time = "") =>
  (dispatch) => {
    let url = `${API_URI}/booking_classes`;
    let filter = "";
    if (start_date && end_date && start_time && end_time) {
      filter = JSON.stringify({
        where: {
          is_cancel: false,
        },
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
        InClassSchedule: {
          $and: [
            { start_time: { $gte: start_time } },
            { start_time: { $lte: end_time } },
          ],
        },
      });

      url = `${url}?filter=${filter}`;
    } else if (start_date && end_date) {
      filter = JSON.stringify({
        where: { is_cancel: false },
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
      });
      url = `${url}?filter=${filter}`;
    }

    dispatch(getAllBookingReportFirstLoad(url));
  };

export const getAllBookingReportFirstLoad =
  (url = "") =>
  (dispatch) => {
    const filter = JSON.stringify({
      where: {
        is_cancel: false,
      },
    });
    url = url ? url : `${API_URI}/booking_classes?filter=${filter}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_BOOKING,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };

// Booking Filter
export const getAllBookingFilter =
  (
    start_date = "",
    end_date = "",
    start_time = "",
    end_time = "",
    price = ""
  ) =>
  (dispatch) => {
    let filter = "";
    let url = `${API_URI}/booking_classes`;
    if (start_date && end_date && price && start_time && end_time) {
      filter = JSON.stringify({
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
        InClassSchedule: {
          $and: [
            { start_time: { $gte: start_time } },
            { start_time: { $lte: end_time } },
          ],
          price: price,
        },
      });
      url = `${url}?filter=${filter}`;
    } else if (start_date && end_date && price) {
      filter = JSON.stringify({
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
        InClassSchedule: { price: price },
      });
      url = `${url}?filter=${filter}`;
    } else if (start_date && end_date && start_time && end_time) {
      filter = JSON.stringify({
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
        InClassSchedule: {
          $and: [
            { start_time: { $gte: start_time } },
            { start_time: { $lte: end_time } },
          ],
        },
      });
      url = `${url}?filter=${filter}`;
    } else if (start_date && end_date) {
      filter = JSON.stringify({
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
      });
      url = `${url}?filter=${filter}`;
    } else if (price) {
      filter = JSON.stringify({ InClassSchedule: { price: price } });
      url = `${url}?filter=${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_BOOKINGS,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
        dispatch(clearLoading());
      });
  };

// Booking Filter gym login
export const getBookingsWithFilter =
  ({
    start_date,
    end_date,
    start_time,
    end_time,
    price,
    is_admin_cancel,
    is_cancel,
  } = {}) =>
  async (dispatch) => {
    let gym_id = localStorage.getItem("gym_id");
    let url = `${API_URI}/booking_classes`;
    let filter = "";
    const startDateFilter = start_date
      ? { $gte: moment(start_date).format("YYYY-MM-DD") }
      : {};
    const endDateFilter = end_date
      ? { $lte: moment(end_date).format("YYYY-MM-DD") }
      : {};
    const startTimeFilter = start_time ? { $gte: start_time } : {};
    const endTimeFilter = end_time ? { $lte: end_time } : {};
    const priceFilter = price ? { price } : {};
    const extraFilters = { gym_id, is_admin_cancel, is_cancel };
    const filters = {
      class_date: {
        ...startDateFilter,
        ...endDateFilter,
      },
      class_start_time: {
        ...startTimeFilter,
        ...endTimeFilter,
      },
      ...priceFilter,
    };
    filter = JSON.stringify({
      where: {
        ...clearEmpties(extraFilters),
        ...clearEmpties(filters),
      },
    });
    url = `${url}?filter=${filter}`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_BOOKING,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };

// booking Reports
export const getAllBookingGymReports =
  (start_date = "", end_date = "", start_time = "", end_time = "") =>
  async (dispatch) => {
    let gym_id = localStorage.getItem("gym_id");
    let url = `${API_URI}/booking_classes`;
    let filter = "";
    if (start_date && end_date && start_time && end_time) {
      filter = JSON.stringify({
        where: { gym_id: gym_id, is_cancel: false },
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
        InClassSchedule: {
          $and: [
            { start_time: { $gte: start_time } },
            { start_time: { $lte: end_time } },
          ],
        },
      });
      url = `${url}?filter=${filter}`;
    } else if (start_date && end_date) {
      filter = JSON.stringify({
        where: { gym_id: gym_id, is_cancel: false },
        InScheduleDate: {
          $and: [{ date: { $gte: start_date } }, { date: { $lte: end_date } }],
        },
      });
      url = `${url}?filter=${filter}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_BOOKING_GYM_REPORT,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };

// booking Reports
export const getAllBookingGymReportsFirstLoad = () => async (dispatch) => {
  dispatch(setLoading());
  let gym_id = localStorage.getItem("gym_id");
  const filter = JSON.stringify({
    where: { gym_id: gym_id, is_cancel: false },
  });
  axios
    .get(`${API_URI}/booking_classes?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ALL_BOOKING_GYM_REPORT,
          payload: data,
        });
        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// booking Reports Cancellation
export const getAllBookingCancellationGymReports =
  ({ start_date, end_date, is_admin_cancel } = {}) =>
  async (dispatch) => {
    dispatch(setLoading());
    let gym_id = localStorage.getItem("gym_id");
    let url = `${API_URI}/booking_classes`;
    let filter = "";
    if (start_date && end_date) {
      filter = JSON.stringify({
        where: {
          createdAt: {
            $between: [`${start_date} 00:00:01`, `${end_date} 23:59:59`],
          },
          gym_id: gym_id,
          is_cancel: true,
          is_admin_cancel,
        },
      });
      url = `${url}?filter=${filter}`;
    } else {
      filter = JSON.stringify({
        where: { gym_id: gym_id, is_cancel: true, is_admin_cancel },
      });
      url = `${url}?filter=${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_BOOKING_CANCELLATION_REPORT,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };

// booking Reports Cancellation
export const getAllCancellationBookingReport =
  ({ start_date, end_date, is_admin_cancel } = {}) =>
  async (dispatch) => {
    dispatch(setLoading());
    let url = `${API_URI}/booking_classes`;
    let filter = "";
    if (start_date && end_date) {
      filter = JSON.stringify({
        where: {
          createdAt: {
            $between: [`${start_date} 00:00:01`, `${end_date} 23:59:59`],
          },
          is_cancel: true,
          is_admin_cancel,
        },
      });
      url = `${url}?filter=${filter}`;
    } else {
      filter = JSON.stringify({ where: { is_cancel: true, is_admin_cancel } });
      url = `${url}?filter=${filter}`;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_ALL_BOOKING,
            payload: data,
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };

export const cancelBookingByAdmin = (id, customFilters) => async (dispatch) => {
  let url = `${API_URI}/booking_classes/${id}`;
  let updateData = {
    is_cancel: 1,
    is_admin_cancel: 1,
  };
  axios
    .put(url, updateData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        dispatch(
          getBookingsWithFilter({
            is_cancel: "0",
            ...customFilters,
          })
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
