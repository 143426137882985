import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import { getUserPayments } from "../../store/auth/authActions";

export const ShowUserPayments = ({ userId }) => {
  const userPayments = useSelector((state) => state.auth.userPayments);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Payments",
    routePath: "/user/payments",
    addNewText: "Add a new payment",
    isBreadcrumbs: false,
    isAddNew: false,
    isSearch: false,
    columns: [
      {
        Header: "Payment Id",
        accessor: "id",
      },
      {
        Header: "Action type",
        id: "action_type",
        accessor: "action_type",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Amount",
        id: "amount",
        accessor: "amount",
        filterable: false,
        Cell: function getPaymentStatus(row) {
          return <div>{row.original.amount / 100}</div>;
        },
      },
      {
        Header: "Merchant Id",
        id: "merchant_id",
        accessor: "merchant_id",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Transaction Id",
        id: "transaction_id",
        filterable: false,
        accessor: "transaction_id",
      },
      {
        Header: "Payment status",
        id: "is_payment",
        accessor: "is_payment",
        filterable: false,
        Cell: function getPaymentStatus(row) {
          return <div>{row.original.is_payment ? "Purchased" : ""}</div>;
        },
      },
      {
        Header: "Date",
        id: "updatedAt",
        filterable: false,
        accessor: "updatedAt",
      },
    ],
  };

  const searchFields = ["name", "id"];
  let history = useHistory();

  useEffect(() => {
    dispatch(getUserPayments(userId));
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={userPayments}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
    />
  );
};
