import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearClass,
  deleteImage,
  getGym,
  updateGymProfile,
} from "../../store/gyms/gymActions";
import { IMAGE_URI } from "../../utils/config";
import isEmpty from "../../validation/is-empty";
import { editGymvalidationPopup } from "../../validation/validation";
import { clearErrors } from "../../store/errors/errorAction";
import ImageUploader from "react-images-upload";
import Modal from "../common/Modal/index";
import { getAllCategories } from "../../store/categories/categoriesAction";
import Map from "../common/mapPopUp/index";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { getAllGenders } from "../../store/classes/classesAction";

class EditGym extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.selectGym.name,
      name_ar: props.selectGym.name_ar,
      description: props.selectGym.description,
      description_ar: props.selectGym.description_ar,
      gym_mobile: props.selectGym.gym_mobile,
      address: props.selectGym.address,
      city: props.selectGym.city,
      state: props.selectGym.state,
      country: props.selectGym.country,
      lattitude: props.selectGym.lattitude,
      longitute: props.selectGym.longitute,
      photos: [],
      gym_categories: props.selectGym.gym_categories,
      category: [],
      categories: [],
      attachment: [],
      category_id: "",
      errors: {},
      gender_id: props.selectGym.gender_id,
      rating_count: props.selectGym.rating_count,
      email: props.selectGym.email,
    };
  }

  componentDidMount() {
    this.props.getAllGenders();
  }

  componentWillUnmount() {
    this.props.clearClass();
    this.props.clearErrors();
  }

  handleCancelEditUser = () => {
    this.props.handleCancelEditUser();
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  onDrop = async (picture) => {
    const photos = [...this.state.photos];
    const file = picture[picture.length - 1];

    if (picture.length) {
      if (picture.length > photos.length) {
        const image = await this.toBase64(file);
        let imagdd = {
          image_data: image,
          is_primary: false,
        };
        photos.push(imagdd);
        this.setState({
          photos: photos,
        });
      }
    }
  };

  onSelect = (selectedList) => {
    this.setState({ categories: selectedList });
  };

  onRemove = (selectedList) => {
    this.setState({ categories: selectedList });
  };
  mapDataclass = (data) => {
    this.setState({
      city: data.city,
      area: data.area,
      state: data.state,
      address: data.address,
      lattitude: data.lat,
      longitute: data.lng,
    });
  };

  handleChangeArabic = (e) => {
    // e.preventDefault()

    const { name, value } = e.target;

    if (!isEmpty(value)) {
      var instance = axios.create();
      instance.defaults.headers.common = {};
      axios
        .get(
          `https://www.googleapis.com/language/translate/v2/detect?key=AIzaSyB_Lz_b22Sf5eKRSHhgxOnoZ8InrtXkpSM&q=${e.target.value}`
        )
        .then((res) => {
          if (
            !isEmpty(res.data.data.detections) &&
            !isEmpty(res.data.data.detections[0]) &&
            !isEmpty(res.data.data.detections[0][0]) &&
            (res.data.data.detections[0][0].language === "ar" ||
              res.data.data.detections[0][0].language === "ur" ||
              res.data.data.detections[0][0].language === "fa")
          ) {
            setAuthToken(localStorage.jwtToken);
            this.setState({ [name]: value });
          }
        });
    } else {
      this.setState({ [name]: value });
    }

    //this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      name_ar,
      description,
      description_ar,
      gym_mobile,

      address,
      city,
      state,
      country,
      lattitude,
      longitute,
      photos,
      gender_id,
    } = this.state;

    // let gym_categories = [...this.state.gym_categories];
    let { categories } = this.state;

    let catte = categories.map((catte) => {
      let data = { id: catte.id };

      return data;
    });

    const gymData = {
      name,
      name_ar,
      description,
      description_ar,
      address,
      city,
      state,
      country,
      lattitude,
      longitute,
      gym_mobile,
      gender_id,

      //  category: catte
    };
    if (photos.length > 0) {
      gymData.photos = photos;
    }
    if (catte.length > 0) {
      gymData.category = catte;
    }

    const { errors, isValid } = editGymvalidationPopup(gymData);

    if (photos.length === 0 && this.props.selectGym.attachments.length === 0) {
      errors.photos = "photos is required";
    }
    if (isValid && Object.keys(errors).length === 0) {
      this.props.clearErrors();
      this.props.updateGymProfile(gymData, this.props.selectGym.id);

      this.props.handleCancelEditUser();
    } else {
      this.setState({ errors });
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeleteImage = (e, id) => {
    e.preventDefault();

    this.props.deleteImage(id, this.props.gym.selectGym.id);
  };

  handleDeleteTmpImage = (id) => {
    let photos = [...this.state.photos];
    photos = photos.filter((photo, index) => index !== id);

    this.setState({
      photos: photos,
    });
  };

  handleChangeNumber = (e) => {
    e.preventDefault();

    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
    if (Number(e.target.value) || e.target.value === "") {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { genders } = this.props.cla;

    const {
      name,
      name_ar,
      description,
      description_ar,
      gym_mobile,
      lattitude,
      longitute,
      photos,
      errors,
      gender_id,
      rating_count,
    } = this.state;

    let ua = navigator.userAgent.match(
        /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
      ),
      browser;
    if (
      navigator.userAgent.match(/Edge/i) ||
      navigator.userAgent.match(/Trident.*rv[ :]*11\./i)
    ) {
      browser = "msie";
    } else {
      browser = ua[1].toLowerCase();
    }

    return (
      <Modal
        id="editUser"
        key="editUser"
        open={true}
        size={"large"}
        header="Update Gym Profile"
        onClose={this.handleCancelEditUser}
      >
        <section className="content">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="name"
                    onChange={this.handleChange}
                    value={name}
                  />
                  {errors.name ? (
                    <span style={{ color: "red" }}>{errors.name}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Name Arabic</label>
                  <input
                    dir="rtl"
                    type="text"
                    className="form-control"
                    id="name_ar"
                    name="name_ar"
                    onChange={this.handleChangeArabic}
                    placeholder="Title"
                    value={name_ar}
                  />
                  {errors.name_ar ? (
                    <span style={{ color: "red" }}>{errors.name_ar}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={description}
                    onChange={this.handleChange}
                  />
                  {errors.description ? (
                    <span style={{ color: "red" }}>{errors.description}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Description Arabic</label>
                  <textarea
                    dir="rtl"
                    className="form-control"
                    id="description_ar"
                    name="description_ar"
                    placeholder="Description Ar"
                    onChange={this.handleChangeArabic}
                    value={description_ar}
                  />
                  {errors.description_ar ? (
                    <span style={{ color: "red" }}>
                      {errors.description_ar}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Mobile</label>
                  <input
                    type="text"
                    maxLength="8"
                    className="form-control"
                    id="no_of_bathroom"
                    name="gym_mobile"
                    placeholder="mobile"
                    onChange={this.handleChangeNumber}
                    value={gym_mobile}
                  />
                  {errors.gym_mobile ? (
                    <span style={{ color: "red" }}>{errors.gym_mobile}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Rating Count</label>
                  <input
                    type="text"
                    className="form-control"
                    id="is_kitchen"
                    name="rating_count"
                    placeholder="rating_count"
                    onChange={this.handleChange}
                    value={rating_count}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Gender</label>
                  <select
                    className="gen-editgym-popup sel-mac"
                    name="gender_id"
                    onChange={this.handleChange}
                    value={gender_id}
                  >
                    <option value="">Select Gender</option>
                    {genders.map((gender_id) => (
                      <option value={gender_id.id} key={gender_id.id}>
                        {gender_id.name}
                      </option>
                    ))}
                  </select>
                  {errors.gender_id ? (
                    <span style={{ color: "red" }}>{errors.gender_id}</span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 ">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">City</label>
                  <input
                    type="text"
                    name="state"
                    className="form-control"
                    onChange={this.onChange}
                    value={this.state.state}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 ">
                <div className="form-group">
                  <label htmlFor="">Address</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    onChange={this.onChange}
                    value={this.state.address}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-offset-1 col-md-4 col-md-offset-1 ">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Lattitude</label>
                  <input
                    type="text"
                    name="state"
                    className="form-control"
                    onChange={this.onChange}
                    value={this.state.lattitude}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-offset-1 col-md-4 col-md-offset-1 ">
                <div className="form-group">
                  <label htmlFor="">Longitute</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    onChange={this.onChange}
                    value={this.state.longitute}
                    readOnly
                  />
                </div>
              </div>
            </div>

            {!isEmpty(lattitude) && !isEmpty(longitute) ? (
              <Map
                google={this.props.google}
                center={{ lat: lattitude, lng: longitute }}
                height="250px"
                width="750px"
                zoom={15}
                mapclass={this.mapDataclass}
              />
            ) : null}

            <div className="row">
              <h6 className="dimensionaligngym">
                Dimensions: 250 * 250 pixels
              </h6>
              <h6 className="dimensionalignmbgym">Max File Size 5mb</h6>
            </div>

            {this.props.selectGym.attachments ? (
              <div className="row align-mar-top">
                <div className="col-md-offset-1 col-md-6 col-md-offset-1">
                  {/*  <label>Images</label> */}
                  <div className="row">
                    {this.props.selectGym.attachments.map((image) => (
                      <div className="col-md-7" key={image.id}>
                        <button className="bttnicon-editgym">
                          <i
                            className="fa fa-trash"
                            style={{
                              marginLeft: "43px",
                            }}
                            aria-hidden="true"
                            onClick={(e) => this.handleDeleteImage(e, image.id)}
                          />
                        </button>
                        <img
                          className="row"
                          style={{
                            width: "250px",
                            height: "250px",
                            // marginLeft: "43px",
                            marginTop: "26px",
                          }}
                          // className="user-image"
                          src={`${IMAGE_URI}/${image.dir}/${image.file_name}`}
                          alt={image.file_name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {photos.map((photo, index) => (
              <div key={index}>
                <button className="bttniconeditgympopup">
                  <i
                    className="fa fa-trash"
                    style={{
                      marginLeft: "43px",
                    }}
                    aria-hidden="true"
                    onClick={() => this.handleDeleteTmpImage(index)}
                  />
                </button>
                <img
                  // className="row"
                  style={{
                    width: "250px",
                    height: "250px",
                    //marginLeft: "130px"
                    // marginLeft: "72px",
                    marginTop: "26px",
                    /*  borderRadius: "50%" */
                  }}
                  src={photo.image_data}
                  alt={photo.filename}
                />
              </div>
            ))}
            <ImageUploader
              withIcon={false}
              withLabel={false}
              // buttonText="Choose image"
              //   fileContainerStyle={{ width: "200px", height: "50px" }}
              fileContainerStyle={{
                width: "200px",
                height: browser === "safari" ? "95px" : "50px",
              }}
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg", "jpeg"]}
              maxFileSize={5242880}
              //  singleImage={true}
            />
            {errors.photos ? (
              <span style={{ color: "red" }}>{errors.photos}</span>
            ) : null}

            <button type="submit" className="btn btn-primary">
              Update
            </button>
            {/*   <button onClick={this.handleSubmit}>Submit</button> */}
          </form>
        </section>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  gym: state.gym,
  cat: state.cat,
  cla: state.cla,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  updateGymProfile,
  getGym,
  deleteImage,
  getAllCategories,
  clearErrors,
  getAllGenders,
  clearClass,
})(EditGym);
