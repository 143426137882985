import React from "react";
import { connect } from "react-redux";
import BoxItem from "./BoxItem";

const Box = (props) => {
  const { stats } = props.auth;
  const { getgymstats } = props.gym;
  const { categoryStats } = props.cat;
  const { subscriptionstats } = props.sub;

  return (
    <div className="row">
      <BoxItem
        color={"bg-aqua"}
        path={"members"}
        title="Members"
        count={stats.data}
        icon={"ion-person"}
      />
      <BoxItem
        color={"bg-aqua"}
        path={"gyms"}
        title="gyms"
        count={getgymstats}
        icon={"ion-person"}
      />
      <BoxItem
        color={"bg-aqua"}
        path={"categories"}
        title="Category"
        count={categoryStats}
        icon={"ion-person"}
      />
      <BoxItem
        color={"bg-aqua"}
        path={"subscriptions"}
        title="Subscription"
        count={subscriptionstats}
        icon={"ion-person"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  gym: state.gym,
  cla: state.cla,
  cat: state.cat,
  sub: state.sub,
});

export default connect(mapStateToProps, {})(Box);
