import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../common/Modal";
import { forgotPassword } from "../../store/auth/authActions";
import { forgotPasswordValidation } from "../../validation/validation";
import { clearErrors } from "../../store/errors/errorAction";
import isEmpty from "../../validation/is-empty";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
    };
  }

  handleCancelForgotPassword = () => {
    this.props.handleCancelForgotPassword();
  };
  handleShowForgotPassword = () => {
    this.props.handleShowForgotPassword();
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.errors.error)) {
      return {
        errors: { common: props.errors.error },
      };
    } else {
      if (state.errors.common) {
        delete state.errors.common;
      }
    }
    return null;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    const addData = {
      email,
    };

    const { errors, isValid } = forgotPasswordValidation(addData);
    if (isValid) {
      this.props.clearErrors();
      this.props.forgotPassword(addData, this.props.history);

      this.setState({ email: "" });
    } else {
      this.setState({ errors });
    }
  };
  handleChange = (e) => {
    const errors = this.state.errors;
    if (errors[e.target.name]) {
      delete errors[e.target.name];
      delete errors.common;
    }
    this.props.clearErrors();
    this.setState({ [e.target.name]: e.target.value, errors });
  };
  render() {
    const { email, errors } = this.state;
    return (
      <Modal
        id="forgotPassword"
        key="forgotPassword"
        open={true}
        size={"mini-light"}
        header="Forgot Password"
        onClose={this.handleCancelForgotPassword}
      >
        <div className="row">
          <div className="col-md-12">
            <form onSubmit={this.handleSubmit}>
              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {errors.email ? (
                    <span style={{ color: "red" }}>{errors.email}</span>
                  ) : null}
                </div>
              </div>
              {errors.common ? (
                <span style={{ color: "red" }}>{errors.common}</span>
              ) : null}

              <div className="box-footer" style={{ textAlign: "center" }}>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { forgotPassword, clearErrors })(
  ForgotPassword
);
