import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { API_URI } from "../../utils/config";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  GET_USERS,
  GET_ADMIN_USERS,
  CLEAR_CURRENT_USER,
  GET_STATS,
  RESET_USERS_PASSWORD,
  GET_GYM_VERIFICATION,
  GET_USER,
  CLEAR_LOADING,
  LOADING,
  GET_CLEAR_CLASS,
  GET_USERS_SUBSCRIPTIONS_REPORTS,
  GET_USER_PAYMENTS,
} from "../types";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { clearEmpties } from "../../utils/helpers";
import { connectToSocket, onDisconnectToSocket } from "../../socket";

// Login - Get User Token
export const loginUser = (userData, history) => (dispatch) => {
  axios
    .post(`${API_URI}/users/login`, userData)
    .then((res) => {
      if (res?.data?.error?.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        // Save to localStorage
        const { token, data } = res.data;
        // Set token to ls
        localStorage.setItem("jwtToken", token);
        if (data.role_id === 4 || data.role_id === 5) {
          localStorage.setItem("gym_id", data.gym.id);
        }
        // Set token to Auth header
        setAuthToken(token);
        // Set current user
        const decoded = jwt_decode(localStorage.jwtToken);
        dispatch(setCurrentUser({ ...data, ...decoded }));
        if (data.role_id === 5) {
          connectToSocket(data);
        }
        history.push("/dashboard");
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

//  Get User User
export const currentUser = () => (dispatch) => {
  axios
    .get(`${API_URI}/me/users`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch(logout());
        window.location.href = `${window.location.origin.toString()}/`;
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        // Save to localStorage
        const { data } = res.data;
        // Set current user
        dispatch(setCurrentUser(data));
        if (data.role_id === 5) {
          connectToSocket(data);
        }
      }
    })
    .catch((err) => {
      dispatch(logout());
      window.location.href = `${window.location.origin.toString()}/`;
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const clearCurrentProfile = () => () => {
  return {
    type: CLEAR_CURRENT_USER,
    payload: {},
  };
};

// Set logged in user
export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  axios
    .get(`${API_URI}/me/users`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        dispatch(logout());
        window.location.href = `${window.location.origin.toString()}/`;
        //window.location.href = "http://aqar.tecnovaters.com/";
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

const logout = () => async (dispatch) => {
  // Remove token from localStorage
  onDisconnectToSocket();
  await localStorage.removeItem("jwtToken");
  await localStorage.removeItem("gym_id");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user
  dispatch(clearCurrentProfile());
  //history.push("/login");
};

// Get all Users
export const getAllUser = () => (dispatch) => {
  const filter = JSON.stringify({
    where: {
      role_id: 3,
    },
  });
  axios
    .get(`${API_URI}/users?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_USERS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getUserPayments = (userId) => (dispatch) => {
  const filter = JSON.stringify({
    where: {
      user_id: userId,
    },
  });
  axios
    .get(`${API_URI}/payments?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_USER_PAYMENTS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getUserPaymentsFilter =
  ({ start_date, end_date, action_type, is_payment } = {}) =>
  (dispatch) => {
    let filter = "";
    const startDateFilter = start_date
      ? {
          $gte: moment(start_date).format("YYYY-MM-DD"),
        }
      : {};
    const endDateFilter = end_date
      ? { $lte: moment(end_date).format("YYYY-MM-DD") }
      : {};

    const extraFilters = { is_payment, action_type };
    const filters = {
      updatedAt: {
        ...startDateFilter,
        ...endDateFilter,
      },
    };

    filter = JSON.stringify({
      where: {
        ...clearEmpties(extraFilters),
        ...clearEmpties(filters),
      },
    });
    axios
      .get(`${API_URI}/payments?filter=${filter}`)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error.message,
          });
        } else {
          const { data } = res.data;
          dispatch({
            type: GET_USER_PAYMENTS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });
      });
  };
// Get all Users
export const getAllUserFilter = (is_newsletter) => (dispatch) => {
  const filter = JSON.stringify({
    where: {
      role_id: 3,
      is_newsletter,
    },
  });
  axios
    .get(`${API_URI}/users?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_USERS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Get all Admins
export const getAllAdmin = () => (dispatch) => {
  const filter = JSON.stringify({
    include: {
      0: "attachment",
    },
    where: {
      role_id: 1,
      is_deleted: 0,
    },
    skip: 0,
    limit: "all",
  });
  axios
    .get(`${API_URI}/users?filter=${filter}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_ADMIN_USERS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const getStats = () => (dispatch) => {
  axios
    .get(`${API_URI}/users/stats`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res;

        dispatch({
          type: GET_STATS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Update User
export const updateUser = (userData, id, history) => (dispatch, getState) => {
  let users = [...getState().auth.users];
  let user = { ...getState().auth.user };
  axios
    .put(`${API_URI}/users/${id}`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        const { data } = res.data;

        users = users.map((user) => {
          if (user.id === data.id) {
            user = { ...user, ...data };
          }
          return user;
        });
        if (user.id === id) {
          dispatch(setCurrentUser(data));
        }
        dispatch({
          type: GET_USERS,
          payload: users,
        });
        toast("User Updated!", {
          position: "top-center",
        });
        if (history) {
          history.push("/members");
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

export const clearClass = () => (dispatch) => {
  dispatch({
    type: GET_CLEAR_CLASS,
  });
};

// Delete User
export const deleteUser = (id) => (dispatch, getState) => {
  let users = [...getState().auth.users];

  axios
    .delete(`${API_URI}/users/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        users = users.filter((user) => user.id !== id);
        dispatch({
          type: GET_USERS,
          payload: users,
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Update Admin
export const changePassword = (userData) => (dispatch) => {
  axios
    .post(`${API_URI}/users/change_password`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        logout(dispatch);
        window.location.href = "/login";
      }
    })
    .catch((err) => {
      if (err.response.data.error) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message,
        });
      }
    });
};

// Forgot Password
export const forgotPassword = (userData, history) => (dispatch) => {
  axios
    .post(`${API_URI}/users/forgot_password`, userData)
    .then((res) => {
      if (res.data.error.code === 1) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error.message,
        });
      } else {
        history.push("/otp-verification");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Reset Password
export const resetPassword = (userData) => (dispatch, getState) => {
  const resetData = getState().auth.reset;
  userData.otp = resetData.otp;

  axios
    .post(`${API_URI}/users/reset_password`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        dispatch({
          type: RESET_USERS_PASSWORD,
          payload: {},
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      })
    );
};

// Otp Verification
export const otpVerification = (userData, history) => (dispatch) => {
  axios
    .post(`${API_URI}/users/verify_otp`, userData)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        dispatch({
          type: RESET_USERS_PASSWORD,
          payload: { otp: userData.otp },
        });
        history.push("/reset-password");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Delete image in view profile page
export const deleteImage = (id) => (dispatch, getState) => {
  let users = [...getState().auth.users];
  let user = { ...getState().auth.user };

  axios
    .delete(`${API_URI}/attachments/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        users = users.filter((user) => user.id !== id);

        user.attachment = null;
        dispatch(setCurrentUser(user));
        dispatch({
          type: GET_USERS,
          payload: users,
        });
        toast("Image Deleted!", {
          position: "top-center",
        });
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error,
      })
    );
};

// Get Password Verification
export const getPasswordVerification = (token) => (dispatch) => {
  axios
    .get(`${API_URI}/password/${token}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_GYM_VERIFICATION,
          payload: data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.error.message,
      });
    });
};

// Single get user
export const getUser = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`${API_URI}/users/${id}`)
    .then((res) => {
      if (res.data.error.code) {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.error,
        });
      } else {
        const { data } = res.data;
        dispatch({
          type: GET_USER,
          payload: data,
        });

        dispatch(clearLoading());
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch(clearLoading());
    });
};

export const setLoading = () => {
  return {
    type: LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

// Gym owner Report
export const planSubscriptionReport =
  ({ is_subscribed } = {}) =>
  (dispatch) => {
    let url = `${API_URI}/users`;
    let filter = "";
    filter = JSON.stringify({
      where: {
        role_id: 3,
        is_subscribed,
      },
    });
    url = `${url}?filter=${filter}`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.error.code) {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.error,
          });
        } else {
          const { data } = res.data;
          const newData = data.map((user) => {
            const plans = user.user_subscriptions || [];
            const plansLength = plans.length;
            const current_plan = plans[0] || {};
            user.current_plan = current_plan;
            const first_plan = plans[plansLength - 1];
            user.first_plan = first_plan || {};
            user.subscription = user.subscription || {};
            user.membership_status = user.is_subscribed
              ? "Active"
              : "Cancelled";
            return user;
          });
          dispatch({
            type: GET_USERS_SUBSCRIPTIONS_REPORTS,
            payload: newData.filter(
              (usr) => usr.user_subscriptions?.length > 0
            ),
          });
          dispatch(clearLoading());
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.error.message,
        });
        dispatch(clearLoading());
      });
  };
