import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../store/errors/errorAction";
import { useHistory, useParams } from "react-router";
import { EntityCreateLayout } from "../entityLayout/entityCreateLayout";
import {
  addViewer,
  getViewer,
  updateViewer,
} from "../../store/viewers/viewerActions";
import { getAllGym } from "../../store/gyms/gymActions";

export const ChangeViewer = ({ isView = false }) => {
  const { id } = useParams();
  const { isLodaing } = useSelector((state) => state.errors);
  const { viewer } = useSelector((state) => state.viewer);
  const { gyms } = useSelector((state) => state.gym);
  //   const [attachments, setAttachments] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const customFields = isView
    ? [
        {
          name: "createdAt",
          label: "Date Joined",
          value: "",
          type: "paragraph",
        },
      ]
    : [];

  const [initData, setInitData] = useState({
    title: "Viewer",
    sideMenuItem: "gym",
    fields: [
      {
        name: "first_name",
        label: "First Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "First Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "last_name",
        label: "Last Name",
        value: "",
        dir: "ltr",
        type: "text",
        placeholder: "Last Name",
        disabled: false,
        isRequired: true,
      },
      {
        name: "mobile",
        label: "Mobile",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Mobile",
        disabled: false,
        isRequired: true,
      },
      {
        name: "email",
        label: "Email",
        dir: "ltr",
        value: "",
        type: "text",
        placeholder: "Email",
        disabled: false,
        isRequired: true,
      },
      {
        name: "gym_id",
        label: "Gym",
        type: "select",
        fieldType: "gyms",
        allValues: [],
        selectedValue: 0,
        disabled: false,
        isRequired: true,
      },
      ...customFields,
    ],
  });

  const handleSave = (saveData) => {
    dispatch(clearErrors);
    if (id) {
      dispatch(updateViewer(saveData, id, history));
    } else {
      dispatch(addViewer(saveData, history));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getViewer(id));
    }
    dispatch(getAllGym());
  }, []);

  useEffect(() => {
    if (viewer.id) {
      updateFields();
    }
  }, [viewer]);

  const updateFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = field.disabled ? field.disabled : isView;
          switch (field.type) {
            case "file":
              return field;
            case "dropdown":
              field.selectedValues = viewer[field.name].map((categories) => {
                return categories.category;
              });
              return field;
            case "select":
              field.selectedValue = viewer[field.name];
              return field;
            case "number":
              field.value = parseFloat(viewer[field.name])
                ? parseFloat(viewer[field.name])
                : field.value;
              return field;
            default:
              field.value = viewer[field.name]
                ? viewer[field.name]
                : field.value;
              return field;
          }
        }),
      };
    });
    if (viewer?.attachment !== undefined) {
      //   setAttachments(viewer.attachment);
    }
    if (viewer?.attachments) {
      //   setAttachments(viewer.attachments);
    }
  };

  useEffect(() => {
    if (gyms.length) {
      updateCustomFields();
    }
  }, [gyms]);

  const updateCustomFields = () => {
    setInitData((prevState) => {
      return {
        ...prevState,
        fields: prevState.fields.map((field) => {
          field.disabled = field.disabled ? field.disabled : isView;

          switch (field.fieldType) {
            case "gyms":
              field.allValues = gyms;
              return field;
            default:
              return field;
          }
        }),
      };
    });
  };

  return (
    <>
      {(viewer?.id && id) || (!viewer?.id && !id) ? (
        <EntityCreateLayout
          isLoading={isLodaing}
          initData={initData}
          submitSaveAction={handleSave}
          isView={isView}
        />
      ) : (
        ""
      )}
    </>
  );
};
