import React, { Component } from "react";
import "./style.css";

const modalBackground = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: "transparent",
  zIndex: "99999",
};

const modalDialog = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  background: "white",
  overflow: "hidden",
  borderRadius: "10px",

  boxShadow: "0 5px 20px rgba(00,00,00, 0.16)",
  zIndex: "99999",
};

const modalFullDialog = {
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",

  zIndex: "99999",
  width: "100%",
};

const headerStyle = {
  background: "#fff",
  color: "#00A699",
  fontSize: "2rem",
  padding: "20px",
  minHeight: "50px",
  height: "50px",
};

const headerText = {
  textTransform: "capitalize",
  transform: "translate(-50%, 0)",
};

const containerStyle = {
  mini: {
    height: "40vh",
    width: "40vw",
  },
  small: {
    height: "55vh",
    width: "55vw",
  },
  "mini-light": {
    height: "35vh",
    width: "25vw",
  },
  medium: {
    height: "70vh",
    width: "70vw",
  },
  large: {
    height: "80vh",
    width: "80vw",
  },
  xlarge: {
    height: "90vh",
    width: "90vw",
  },
  default: {
    height: "85.86525759577279vh",
    width: "67.1875vw",
  },
  //Invite User Modal
  "small-list": {
    height: "65vh",
    width: "25vw",
  },

  format: {
    height: "29vh",
    width: "20vw",
  },
  "color-picker": {
    height: "55vh",
    width: "20.5vw",
  },
  // for tree //commom/Tree
  "custom-mini": {
    height: "28vh",
    width: "32vw",
  },

  // add node
  "custom-node": {
    height: "34.5vh",
    width: "20vw",
  },
};

class ModalContainer extends Component {
  render() {
    let size = this.props.size;
    let containerSize =
      size && size !== "custom"
        ? { ...containerStyle[size] }
        : { height: this.props.height, width: this.props.width };
    let modalDialogStyle = this.props.fullScreen
      ? modalFullDialog
      : { ...modalDialog, ...containerSize };

    return this.props.open ? (
      <div
        className="custom-modal-container"
        onWheel={(ev) => {
          ev.stopPropagation();
        }}
      >
        <div className="modal-background" style={modalBackground} />
        <div
          role="dialog"
          className={`modal-dialog-content ${this.props.headerClass}`}
          style={modalDialogStyle}
        >
          {this.props.header !== undefined && (
            <header style={headerStyle}>
              <span style={headerText}>{this.props.header}</span>
            </header>
          )}

          <div
            onClick={() => this.props.onClose()}
            className="option"
            style={{ float: "right", color: "#111" }}
          >
            <i className="fa fa-close" />
          </div>
          <div className="custom-modal-content">{this.props.children}</div>
          <footer />
        </div>
      </div>
    ) : null;
  }
}

ModalContainer.defaultProps = {
  headerClass: "",
  size: "large",
  onClose: () => {},
  fullScreen: false,
  extraIcons: false,
};

export default ModalContainer;
