import Validator from "validator";

export const fieldValidate = (fieldsData, attachment, attachments) => {
  let errors = {};
  fieldsData.forEach((fieldData) => {
    switch (fieldData.type) {
      case "file": {
        if (
          fieldData.isRequired &&
          Array.isArray(fieldData.files) &&
          !fieldData.files.length &&
          !attachment &&
          !attachments.length
        ) {
          errors[fieldData.name] = `${fieldData.label} is required!`;
        }
        break;
      }
      case "dropdown":
        if (fieldData.isRequired && !fieldData.selectedValues.length) {
          errors[fieldData.name] = `${fieldData.label} is required!`;
        }
        break;
      case "select":
        if (fieldData.isRequired && !fieldData.selectedValue) {
          errors[fieldData.name] = `${fieldData.label} is required!`;
        }
        break;
      case "map":
        break;
      case "number":
        if (fieldData.isRequired && parseInt(fieldData.value) <= -1) {
          errors[fieldData.name] = `${fieldData.label} is required!`;
        }
        break;
      default:
        if (fieldData.isRequired && Validator.isEmpty(fieldData.value.trim())) {
          errors[fieldData.name] = `${fieldData.label} is required!`;
        }
    }
  });
  return { errors, isValid: Object.keys(errors).length === 0 };
};
