import React from "react";

export const CustomSelect = ({ field, onSelectChange, errors }) => {
  return (
    <div
      className="form-group col-md-4 col-md-offset-1"
      style={{ height: "59px" }}
    >
      <label htmlFor="exampleInputEmail1">{field.label}</label>
      <select
        className="categoryid sel-mac"
        name={field?.name}
        onChange={onSelectChange}
        value={field.selectedValue}
      >
        <option value="">{`Select ${field?.label || "Gender"}`}</option>
        {field.allValues.map((value) => (
          <option key={value.id} value={value.id}>
            {value.name}
          </option>
        ))}
      </select>
      {errors.gender_id ? (
        <span style={{ color: "red" }}>{errors.gender_id}</span>
      ) : null}
    </div>
  );
};
