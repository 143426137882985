import React from "react";
import { useParams } from "react-router";

import { ChangeClass } from "../Classes/changeClass";
import { ShowClassesSchedules } from "./showClassesSchedules";

export const ChangeAdminClass = ({ isView = false }) => {
  const { id } = useParams();

  return (
    <>
      <ChangeClass isView={isView} />
      {isView ? <ShowClassesSchedules id={id} /> : ""}
    </>
  );
};
