import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import { planSubscriptionReport } from "../../store/auth/authActions";

export const ShowPlanSubscriptionTable = () => {
  const auth = useSelector((state) => state.auth);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const tableProps = {
    name: "Member Subscription Report",
    routePath: "/plan-subscription-report",
    addNewText: "Add Plan subscription report",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: true,
    isDateFilters: false,
    isTimeFilters: false,
    isPriceFilter: false,
    isCsvConvert: true,
    isMemberSubscribedFilter: true,
    columns: [
      {
        Header: "Member Id",
        id: "row",
        accessor: "id",
        maxWidth: 75,
        filterable: false,
      },
      {
        Header: "Member Name",
        id: "first_name",
        accessor: "first_name",
        filterable: false,
        Cell: function getName(row) {
          return <div>{row.original ? row.original.first_name : ""}</div>;
        },
      },
      {
        Header: "Member email",
        id: "email",
        accessor: "email",
        filterable: false,
        Cell: function getEmail(row) {
          return <div>{row.original ? row.original.email : ""}</div>;
        },
      },
      {
        Header: "Mobile",
        id: "mobile",
        filterable: false,
        accessor: "mobile",
        Cell: function getMobile(row) {
          return <div>{row.original ? row.original?.mobile : ""}</div>;
        },
      },
      {
        Header: "First Purchase",
        id: "createdAt",
        maxWidth: 130,
        filterable: false,
        Cell: function isSubscribed(row) {
          return (
            <div>
              {row.original.first_plan?.createdAt
                ? row.original.first_plan?.createdAt
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Last Purchase",
        id: "createdAt",
        maxWidth: 130,
        filterable: false,
        Cell: function isSubscribed(row) {
          return (
            <div>
              {row.original.current_plan
                ? row.original.current_plan?.createdAt
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Membership Status",
        id: "membership_status",
        accessor: "membership_status",
        maxWidth: 130,
        filterable: false,
      },
      {
        Header: "Subscription Name",
        id: "subscription.name",
        filterable: false,
        accessor: "subscription.name",
        Cell: function getSubscription(row) {
          return (
            <div>
              {row.original.subscription ? row.original.subscription?.name : ""}
            </div>
          );
        },
      },
      {
        Header: "Credits",
        id: "credits",
        accessor: "credits",
        filterable: false,
        Cell: function getCredits(row) {
          return (
            <div>
              {row.original.current_plan
                ? row.original.current_plan?.subscription?.credits
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Validity In Months",
        id: "subscription.period",
        accessor: "subscription.period",
        filterable: false,
        Cell: function getPeriod(row) {
          return (
            <div>
              {row.original.subscription
                ? row.original.subscription?.period
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Price",
        id: "price",
        accessor: "price",
        filterable: false,
        Cell: function GetPrice(row) {
          return (
            <div>
              {row.original.current_plan
                ? row.original.current_plan?.subscription?.price
                : ""}
            </div>
          );
        },
      },
    ],
  };

  const searchFields = ["first_name", "email", "mobile", "createdAt"];
  let history = useHistory();

  const csvHeaders = [
    { label: "User Id", key: "id" },
    { label: "Member Name", key: "first_name" },
    { label: "Member Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "First Purchase", key: "first_plan.createdAt" },
    { label: "Last Purchase", key: "current_plan.createdAt" },
    { label: "Membership Status", key: "membership_status" },
    { label: "Subscription Name", key: "current_plan.subscription.name" },
    { label: "Credits", key: "current_plan.subscription.credits" },
    { label: "Validity In Months", key: "current_plan.subscription.period" },
    { label: "Price", key: "current_plan.subscription.price" },
  ];

  const excelHeaders = [
    {
      label: "Member Name",
      fieldName: "gym_name",
      valuePath: "first_name",
    },
    {
      label: "Member Email",
      fieldName: "email",
      valuePath: "email",
    },
    {
      label: "Subscription Name",
      fieldName: "subscription_name",
      valuePath: "subscription.name",
    },
    {
      label: "Mobile",
      fieldName: "gym_mobile",
      valuePath: "mobile",
    },
    {
      label: "",
      fieldName: "createdAt",
      valuePath: "current_plan.createdAt",
    },
    {
      label: "Credits",
      fieldName: "subscription_credits",
      valuePath: "current_plan.subscription.credits",
    },
    {
      label: "Validity In Months",
      fieldName: "subscription_period",
      valuePath: "subscription.period",
    },
    {
      label: "Price",
      fieldName: "subscription_price",
      valuePath: "current_plan.subscription.price",
    },
  ];

  const customFilter = ({ is_subscribed }) => {
    dispatch(planSubscriptionReport({ is_subscribed }));
  };

  const clearFilter = () => {
    dispatch(planSubscriptionReport());
  };

  useEffect(() => {
    dispatch(planSubscriptionReport());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={auth.usersSubscriptions}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      customFilter={customFilter}
      csvHeaders={csvHeaders}
      excelHeaders={excelHeaders}
      clearFilter={clearFilter}
      csvFilePrefix="member_subscription_report"
    />
  );
};
