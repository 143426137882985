import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  getAllReviewsClass,
  updateReview,
} from "../../store/reviews/reviewActions";

export const ShowClassesReviews = () => {
  const rev = useSelector((state) => state.rev);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const tableProps = {
    name: "Classes Reviews",
    routePath: "/reviews/admin-classes",
    addNewText: "Add new class review",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: false,
    isDateFilters: false,
    isTimeFilters: false,
    isPriceFilter: false,
    isCsvConvert: false,
    isExcelConvert: false,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        maxWidth: 75,
        filterable: false,
      },
      {
        Header: "Member Name",
        accessor: "user.first_name",
        paddingTop: "35px",
      },
      {
        Header: "Member Reviews",
        accessor: "description",
        paddingTop: "35px",
      },
      {
        Header: "Gym Name",
        accessor: "classTable.gym.name",
        paddingTop: "35px",
      },
      {
        Header: "Class Name",
        accessor: "classTable.name",
        paddingTop: "35px",
      },
      {
        Header: "Rating",
        id: "row_star",
        type: "rating",
        accessor: "rating",
        maxWidth: 150,
        filterable: false,
      },

      {
        Header: "Action",
        maxWidth: 350,
        type: "actions",
        isViewButton: true,
        isStatusButton: true,
      },
    ],
  };

  const searchFields = ["class", "description"];
  let history = useHistory();

  const updateItem = (is_active, id) => {
    dispatch(updateReview(is_active, id));
  };

  useEffect(() => {
    dispatch(getAllReviewsClass());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={rev.reviews}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
    />
  );
};
