import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { EntityViewLayout } from "../entityLayout/entityViewLayout";
import {
  getAllReviewsGymLogin,
  updateReview,
} from "../../store/reviews/reviewActions";

export const ShowGymReviews = () => {
  const rev = useSelector((state) => state.rev);
  const { isLodaing } = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const role = user.role_id || user.roleId;
  const isViewer = role === 5;
  const isViewerOrGym = role === 5 || role === 4;

  const tableProps = {
    name: "Gym Reviews",
    routePath: "/reviews/gyms",
    addNewText: "Add new gym review",
    isBreadcrumbs: true,
    isAddNew: false,
    isSearch: true,
    isFilters: false,
    isDateFilters: false,
    isTimeFilters: false,
    isPriceFilter: false,
    isCsvConvert: false,
    isExcelConvert: false,
    columns: [
      {
        Header: "S.No",
        id: "row",
        type: "index",
        maxWidth: 75,
        filterable: false,
      },
      ...(isViewerOrGym
        ? [
            {
              Header: "Member Name",
              accessor: "name",
              paddingTop: "35px",
            },
          ]
        : [
            {
              Header: "Member Name",
              accessor: "user.first_name",
              paddingTop: "35px",
            },
            {
              Header: "Member Id",
              accessor: "user.id",
              paddingTop: "35px",
            },
          ]),
      {
        Header: "Member Reviews",
        accessor: "description",
        paddingTop: "35px",
      },
      {
        Header: "Rating",
        id: "row_star",
        type: "rating",
        accessor: "rating",
        maxWidth: 150,
        filterable: false,
      },
      isViewer
        ? {
            Header: "Action",
            maxWidth: 350,
            type: "actions",
            isViewButton: true,
            isStatusButton: false,
          }
        : {
            Header: "Action",
            maxWidth: 350,
            type: "actions",
            isViewButton: true,
            isStatusButton: true,
          },
    ],
  };

  const searchFields = ["class", "description"];
  let history = useHistory();

  const updateItem = (is_active, id) => {
    dispatch(updateReview(is_active, id));
  };

  useEffect(() => {
    dispatch(getAllReviewsGymLogin());
  }, []);

  return (
    <EntityViewLayout
      isLoading={isLodaing}
      items={rev.reviews}
      searchFields={searchFields}
      tableProps={tableProps}
      history={history}
      updateItem={updateItem}
    />
  );
};
